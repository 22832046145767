let _localCounter = 1 // 同一个方法名快速请求时，可能 Date.now() 还没有变化
function invokeJSBridge (method, arg) {
  return new Promise((resolve, reject) => {
    if (window.XLJSWebViewBridge && typeof window.XLJSWebViewBridge.sendMessage === 'function') {
      let callbackName = `${method}CallbackName${Date.now()}`
      if (window[callbackName]) {
        callbackName = `${method}CallbackName${Date.now()}_${_localCounter}`
        _localCounter += 1
      }
      window[callbackName] = (res) => {    // todo: 增加定时器去垃圾收集那些没有被客户端回调的方法
        try {
          resolve({ result: 'ok', data: JSON.parse(res) })
        } catch (e) {
          resolve({ result: 'error', data: res })
        }
        window[callbackName] = null
      }
      window.XLJSWebViewBridge.sendMessage( // 桥协议
        method, // 方法名
        JSON.stringify(arg || {}),  // 传参
        callbackName  // 回调
      )
    } else {
      reject(arg)
    }
  })
}

export default function (context, inject) {
  inject('slnative', invokeJSBridge) // $native('方法名', 参数对象).then(返回参数 => {}).catch(原参数 => {})
}
