export function clientType () {
  const isIOS = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
  if (isIOS) return 'ios'
  const isAndroid = /(Android)/i.test(navigator.userAgent)
  if (isAndroid) return 'android'
  const isMac = /macintosh|mac os x|thunderbrowser/i.test(navigator.userAgent)
  if (isMac) return 'mac'
  return 'pc'
}
export function stat (type, action, category, options, data) {
  if (process.env.NODE_ENV === 'development') {
    console.log(type, action, category, options, data)
  }

  xla.push({
    type,
    category: category || 'page_event',
    action,
    extdata: {
      clientType: clientType(),
      ...options,
      ...data
    }
  })
}

export function VIPStat (type, action, category, options, data) {
  if (process.env.NODE_ENV === 'development') {
    console.log(type, action, category, options, data)
  }

  xla2.push({
    type,
    category: category || 'page_event',
    action,
    extdata: {
      clientType: clientType(),
      ...options,
      ...data
    }
  })
}

export function normalStat (category, action, extdata) {
  stat('event', action, category, {}, {
    ...(extdata || {})
  })
}
