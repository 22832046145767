import { fetchDataWithShoulei } from '@/api/fetch-res'
import axios from 'axios'

export function getLotteryInfo (uid) {
  return fetchDataWithShoulei('/xlppc.coin.lottery.api/api/get_lottery_info', {
    uid: Number(uid)
  })
}

export function lottery ({ uid, sessionId, ...metadata }) {
  return fetchDataWithShoulei('/xlppc.coin.lottery.api/api/lottery', {
    uid: Number(uid),
    session_id: sessionId
  }, {
    method: 'POST',
    headers: {
      clientversion: String(metadata.versionCode),
      osver: String(metadata.systemVersion),
      appname: String(metadata.applicationId)
    }
  })
}

export function getUserId () {
  return axios.post('http://soagw.live.xunlei.com/xllive.zblistservice.s/v1/GetSlAdsPushRoom.json',{
    module_id: 10001
  }).then(res => {
    if (res.status === 200 && res.data && res.data.data) {
      return res.data.data.userid || 0
    }else {
      console.log(res.status)
    }
  }).catch(res => console.log(res.status))
}
