export default {
  shouleiAPI: 'prod',
  driveAPI: 'prod',
  userAPI: 'prod',
  // shouleiAPI: 'test',
  // driveAPI: 'test',
  // userAPI: 'test',
  appid: 165,
  slAppid: 40,
  iosAudioAppid: 80,
  slAppname: 'android',
  staticHost: 'https://static-xl.a.88cdn.com'
}
