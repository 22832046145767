import { platform } from '@/utils/util'

export default function (context) {
  context.userAgent = process.server ? context.req.headers['user-agent'] : navigator.userAgent
  context.store.commit('setUa', context.userAgent)
  if (process.client) {
    if (
      (context.userAgent && context.userAgent.match(/iThunder/g)) ||
      window.WebViewJavascriptBridge ||
      window.XLJSWebViewBridge
    ) {
      context.store.commit('setIsNative', true)
      if (window.WebViewJavascriptBridge) {
        context.store.commit('setOs', 'ios')
      } else {
        context.store.commit('setOs', 'android')
      }
    }
    context.store.commit('setPlatform', platform())
  }
}
