import { getStore } from '@/plugins/vuex-store-plugin'

/**
 * 获取用户信息
 * @author zhaobing
 * @param {array} infos - 需要的用户信息
 * @return {Promise}
 * 支持以下参数
 *  userId
 sessionId
 userNick
 userName
 peerid 如果在客户端，获取的是客户端peerid，web端为账号构造的
 thunder 迅雷版本号
 thunderComponent 迅雷浏览器组件版本号
 */
export default function getUserInfo (infos = ['peerid', 'userid', 'version', 'sessionid', 'isNative']) {
  const storeUserInfo = getStore().state.users.curUser
  const userInfo = Object.assign({}, storeUserInfo, {
    version: storeUserInfo.thunder || '',
    userid: storeUserInfo.userId || '0',
    sessionid: storeUserInfo.sessionId || '',
    peerid: storeUserInfo.peerId || 'AAAAAAAAAAAAAAAA',
    isNative: getStore().state.isNative
  })

  return Promise.resolve(infos.map(key => userInfo[key]))
}
