import axios from 'axios'
import config from '@/config'
import getUserInfo from '@/utils/get-user-info'

export let client = null

if (process.server) {
  axios.defaults.timeout = 1500
} else if (process.browser) {
  axios.defaults.timeout = 30000
  axios.interceptors.request.use(function (config) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('offline'))
    }
    return config
  })
  client = (() => require('@/utils/clientApi').default)()
}


export const DRIVE_API = {
  test: 'https://api-alpha-drive.office.k8s.xunlei.cn/drive/v1/',
  prod: 'https://api-pan.xunlei.com/drive/v1/'
}

export const SHOULEI_API = {
  test: 'https://test-api-shoulei-ssl.xunlei.com',
  prod: 'https://api-shoulei-ssl.xunlei.com'
}

/**
 * 获取云盘相关的接口：http://drive.office.k8s.xunlei.cn/?urls.primaryName=file.swagger.json#/
 * @param {string} url - 接口地址
 * @param {object} data - 数据
 * @param {object} option - 选项（method：请求方法'get'或'post'；headers：放入请求头的内容）
 */
export async function fetchDataForDrive(url, data = {}, option = {}) {
  url = option.env
    ? `${DRIVE_API[option.env]}${url}`
    : `${DRIVE_API[config.driveAPI]}${url}`

  const parsedData = await parseData(data, option)
  const { method, params, userInfo } = parsedData

  // const cookie = (!process.server && document.cookie) || ''

  // 每次请求前从客户端获取accessToken
  let accessToken
  try {
    accessToken = await client.ssoCallFunction('getAccessToken')
    console.log('accessToken: ', accessToken)
    if (checkIsJSON(accessToken) && accessToken.includes('error')) throw new Error()
  } catch (error) {
    return Promise.reject('请重新登录')
  }
  const Authorization = 'Bearer ' + accessToken

  // 一键导入的接口只能配authorization，加上其他都会出现跨域
  const headers = Object.assign({}, {
    'Authorization': Authorization,
    // 'x-peer-id': userInfo.peerId,
    // 'x-guid': userInfo.guid
  }, option.headers)

  return axios({
    method,
    url,
    data,
    params,
    headers
  })
    .then(res => {
      return res.data
    })
    .catch(res => {
      return Promise.reject(res.response.data)
    })
}


/**
 * 解析请求参数
 * @param {object} data - 数据
 * @param {object} option - 选项
 */
async function parseData(data = {}, option = {}) {
  option = Object.assign(
    {},
    {
      method: data._m || 'get'
    },
    option
  )
  const method = option.method

  const cookie = (!process.server && document.cookie) || ''
  let [peerId, userId = 0, version, sessionId] = await getUserInfo()
  peerId =
    peerId || getCookie('peerid', cookie) || getCookie('deviceid', cookie)

  const guid = md5(peerId || random(32))
  const userInfo = {
    userId,
    sessionId,
    version,
    guid,
    peerId
  }
  const headerStrings = [...((data && data._h) || [])]

  delete data._h
  delete data._m

  let params = data
  if (typeof option === 'object' && option.noAssignParams) {
    params = null;
  }
  data = null

  return {
    method,
    params,
    headerStrings,
    userInfo,
    data
  }
}

function checkIsJSON(str) {
  if (typeof str === 'string') {
    try {
      var obj = JSON.parse(str)
      if (typeof obj === 'object' && obj) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
  return false
}
