import axios from 'axios'
import jsonp from 'then-jsonp'
import getUserInfo from '@/utils/get-user-info'
import config from '@/config'
import { errorMap } from '@/utils/error-message'
import { getCookie, random, checkIsJSON } from '@/utils/util'
import md5 from 'blueimp-md5'
import { getStore } from '@/plugins/vuex-store-plugin'

let client = null

if (process.server) {
  axios.defaults.timeout = 1500
} else if (process.browser) {
  axios.defaults.timeout = 30000
  axios.interceptors.request.use(function (config) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('offline'))
    }
    return config
  })
  client = (() => require('@/utils/clientApi').default)()
}

const serverEnv = (typeof global !== 'undefined' && global.serverEnv) || {}
const API_HOST = serverEnv.API_HOST
  ? 'http://' + serverEnv.API_HOST
  : 'https://api-xl9-ssl.xunlei.com'

const SL_API = {
  test: 'http://api-xl9-ssl.xunlei.com/sl_dev',
  pre: 'http://api-xl9-ssl.xunlei.com/sl_pre',
  prod: `${API_HOST}/sl`
}

export const SHOULEI_API = {
  mock: 'https://easy-mock.com/mock/5dfc6f66d1f75a6575846877/red-envelope',
  test: 'https://test-api-shoulei-ssl.xunlei.com',
  prod: serverEnv.SHOULEI_API || 'https://api-shoulei-ssl.xunlei.com'
}

const XLWS_API = {
  test: 'http://api-xl9-ssl.xunlei.com/xlws',
  prod: 'https://api-xl9-ssl.xunlei.com/xlws'
}

export const XLUSER_API = {
  test: 'https://dev-xluser-ssl.xunlei.com',
  prod: 'https://xluser-ssl.xunlei.com'
}

const DY_API = {
  test: 'https://dy1-vip-ssl.xunlei.com/general/api/xdriver',
  prod: 'https://dy1-vip-ssl.xunlei.com/general/api/xdriver'
}

/**
 * 转换 header 参数
 */
function headerStringsToObject (headerStrings) {
  return headerStrings.reduce((prev, current) => {
    const [key, value] = current.split(':')
    prev[key] = value
    return prev
  }, {})
}

/**
 * 获取数据接口（直接连接服务端API，不走Node.js网关）
 * @param {string} url - 接口地址
 * @param {object} data - 数据
 * @param {object} option - 选项（method：请求方法'get'或'post'；headers：放入请求头的内容）
 */
export async function fetchDataWithShoulei (url, data = {}, option = {}) {
  url = option.env ? `${SHOULEI_API[option.env]}${url}` : `${SHOULEI_API[config.shouleiAPI]}${url}`

  const parsedData = await parseData(data, option)
  const {
    method,
    params,
    userInfo
  } = parsedData

  const cookie = (!process.server && document.cookie) || ''

  const headers = Object.assign({}, {
    'Peer-Id': userInfo.peerId,
    Guid: userInfo.guid,
    'User-Id': userInfo.userId === '0' ? '' : userInfo.userId,
    'Session-Id': userInfo.sessionId,
    'Version-Code': process.env.APP_VERSION || '1.0.0',
    'Account-Id': config.appid,
    'Credit-Key': getCookie('creditkey', cookie)
  }, option.headers)
  return axios({
    // 本地开发时可以打开proxy，使用 whistle 代理请求，可以抓包
    // proxy: {
    //   host: '127.0.0.1',
    //   port: 8899
    // },
    method,
    url,
    data,
    params,
    headers
  })
    .then((res) => {
      // 若返回错误信息，则转换成中文的错误信息，放在 errorText
      if (!(res.data.result === 'ok' || res.data.result === 0)) {
        res.data.errorText = errorMap(res.data)
      }

      return res.data
    })
    .catch(res => { // 返回错误信息
      console.log(res)
      if (res instanceof Error) {
        res.response = { data: { result: res.message } }
      }
      return Promise.reject(Object.assign({}, res.response, { message: errorMap(res.response && res.response.data) }))
    })
}

/**
 * 解析请求参数
 * @param {object} data - 数据
 * @param {object} option - 选项
 */
export async function parseData (data = {}, option = {}) {
  option = Object.assign({}, {
    method: data._m || 'get'
  }, option)
  const method = option.method

  const cookie = (!process.server && document.cookie) || ''
  let [peerId, userId = 0, version, sessionId] = await getUserInfo()
  peerId = peerId || getCookie('peerid', cookie) || getCookie('deviceid', cookie)

  const guid = md5(peerId || random(32))
  const userInfo = {
    userId,
    sessionId,
    version,
    guid,
    peerId
  }
  const headerStrings = [
    ...((data && data._h) || [])
  ]

  delete data._h
  delete data._m

  let params
  if (method === 'get') {
    params = data
    data = null
  }

  return {
    method,
    params,
    headerStrings,
    userInfo,
    data
  }
}

/**
 * api-xl9-ssl.xunlei.com/xlws 服务
 * @param {string} url - 接口地址
 * @param {object} data - 数据
 * @param {object} option - 选项（method：请求方法'get'或'post'；headers：放入请求头的内容）
 */

export async function fetchDataWithXLWS (url, data = {}, option = {}) {
  url = option.env
    ? `${XLWS_API[option.env]}${url}`
    : `${XLWS_API[config.shouleiAPI]}${url}`

  const parsedData = await parseData(data, option)
  const { method, params, userInfo } = parsedData

  let appid
  if (data.appid !== undefined) {
    appid = data.appid
  } else {
    appid = config.slAppid
  }

  const cookie = (!process.server && document.cookie) || ''

  const headers = Object.assign(
    {},
    {
      'Peer-Id': userInfo.peerId,
      'Device-Id': userInfo.deviceId,
      Guid: userInfo.guid,
      'User-Id': userInfo.userId === '0' ? '' : userInfo.userId,
      'Session-Id': userInfo.sessionId,
      'Version-Code': process.env.APP_VERSION,
      'Account-Id': appid,
      'Credit-Key': getCookie('creditkey', cookie)
    },
    option.headers
  )

  return axios({
    method,
    url,
    data,
    params,
    headers
  })
}

export const fetchJSONP = function (url, option) {
  return new Promise((resolve, reject) => {
    jsonp('GET', url, option, function (err, data) {
      if (err) {
        const data = {
          url: url,
          option: option
        }
        console.log(err)
        reject(err)
      } else {
        resolve(data)
      }
    })
  })
}

/**
 * 访问手雷接口
 * @param {string} url - 接口地址
 * @param {object} data - 数据
 */
export async function fetchSlRes (url, data = {}, option = {}) {
  url = url.indexOf('http') === 0 ? url : option.env ? `${SL_API[option.env]}${url}` : `${SL_API[config.shouleiAPI]}${url}`

  const parsedData = await parseData(data, option)
  let {
    method,
    params,
    headerStrings,
    userInfo
  } = parsedData

  headerStrings = [
    ...headerStrings,
    `Peer-Id:${userInfo.peerId}`,
    `Guid:${userInfo.guid}`,
    `User-Id:${userInfo.userId}`,
    `Session-Id:${userInfo.sessionId}`,
    `Version-Code:${process.env.APP_VERSION}`  // 使用前端版本号
  ]
  const headers = headerStringsToObject(headerStrings)

  const res = await fetchByApiProxy(url, {
    method,
    params,
    headerStrings,
    headers,
    userInfo,
    data: parsedData.data
  })
  if (option.noAutoCatch || res.result === 'ok' || res.code === 0) {
    return res
  } else {
    throw new Error('data error')
  }
}

/**
 * 通过 API 网关请求数据
 */
export function fetchByApiProxy (url, {
  method = 'get',
  params = {},
  data = {},
  headers = {},
  headerStrings = [],
  timeout = 1000,
  userInfo,
  ignoreSessionCheck
}) {
  if (!ignoreSessionCheck) {
    // 鉴权参数只能通过 params 传递
    params._sessid = userInfo.sessionId
    params._uid = userInfo.userId
  }

  // header通过参数来传避免options请求
  params._h = headerStrings

  return axios({
    // 本地开发时可以打开proxy，使用 whistle 代理请求，可以抓包
    // proxy: {
    //   host: '127.0.0.1',
    //   port: 8899
    // },
    method,
    url,
    data,
    params
  })
    .then(res => {
      return res.data
    })
}

// jsonp请求xlusre接口
export async function fetchJsonpWithUser (url, option = {}) {
  url = option.env
    ? `${XLUSER_API[option.env]}${url}`
    : `${XLUSER_API[config.userAPI]}${url}`
  let [peerId, userId = 0, version, sessionId] = await getUserInfo()
  peerId = peerId || getCookie('peerid', cookie) || getCookie('deviceid', cookie)
  option.qs = {
    ...option.qs,
    userid: userId,
    sessionid: sessionId,
    deviceid: peerId
  }
  return fetchJSONP(url, option)
}

// 请求预举报、申诉接口，要求云盘的登陆态
// http://wiki.xunlei.cn/pages/viewpage.action?pageId=53573055
export async function fetchDataForDy (url, data = {}, option = {}) {
  url = option.env ? `${DY_API[option.env]}${url}` : `${DY_API[config.driveAPI]}${url}`

  const parsedData = await parseData(data, option)
  const {
    method,
    params
  } = parsedData

  // 每次请求前从客户端获取accessToken
  let accessToken
  try {
    if (client.isIOS()) {
      const [err, user] = await client.callNativeHandler('userInfo')
      accessToken = user.authorize
    } else if (client.isNative()) {
      accessToken = await client.ssoCallFunction('getAccessToken')
    } else {
      accessToken = await getStore().state.users.sso.accessToken
    }
    if (checkIsJSON(accessToken) && accessToken.includes('error')) throw new Error()
  } catch (error) {
    return Promise.reject('请重新登录')
  }

  const Authorization = 'Bearer ' + accessToken

  const headers = Object.assign({}, {
    'Authorization': Authorization
  }, option.headers)

  return axios({
    method,
    url,
    data,
    params,
    headers
  })
}
