
import account from '@/utils/account'
const IS_NATIVE = process.client ? typeof window.XLJSWebViewBridge === 'object' : false
/**
 * 将一个支持callback的函数转换成支持promise的
 * @method promisefy
 * @param {Function} fn - 支持callback的函数
 * @param {Object} context - 执行环境
 * @returns {Function}
 */
export function promisefy (fn, context) {
  return function (...args) {
    return new Promise((resolve, reject) => {
      const cb = (err, payload) => {
        if (err) {
          reject(err)
        } else {
          resolve(payload)
        }
      }
      fn.apply(context, [...args, cb])
    })
  }
}

/**
 * 设置cookie
 * @author @zhaobing
 * @param {String} key
 * @param {String} value
 * @param {Object} opt
 * @property {String} opt.path - 例如 '/', '/mydir') 如果没有定义，默认为'/'。
 * @property {String} opt.domain - (例如 'example.com'， '.example.com' (包括所有子域名),
 *  'subdomain.example.com') 如果没有定义，默认为当前文档位置的路径的域名部分。
 * @property {String} opt.maxAge - max-age-in-seconds (例如一年为60*60*24*365)
 * @property {String} opt.expires - 过期时间，不需要转换成UTC
 * @property {String} opt.secure - cookie只通过https协议传输，默认为false
 */
export function setCookie (key, value, {
  path = '/',
  domain,
  maxAge,
  expires,
  secure
} = {}) {
  let cookieStr = key + '=' + encodeURIComponent(value)

  path && (cookieStr += ';path=' + path)
  domain && (cookieStr += ';domain=' + domain)
  maxAge && (cookieStr += ';max-age=' + maxAge)
  expires && (cookieStr += ';expires=' + expires.toUTCString())
  secure && (cookieStr += ';secure=' + secure)

  document.cookie = cookieStr
}

/**
 * @method getCookie
 * @desc 获取cookie值
 * @param name {String} - cookie字段名
 * @returns {String} - cookie值
 */
export function getCookie (name, cookie = '') {
  if (!cookie && process.client) {
    cookie = document.cookie
  }
  const result = (cookie.match(new RegExp('(^' + name + '| ' + name + ')=([^;]*)')) == null) ? '' : RegExp.$2

  return result
}

/***
 * 删除cookie中指定变量函数
 * @param {string} name Cookie名称
 */
export function deleteCookie (name) {
  const myDate = new Date()
  myDate.setTime(-1000) // 设置时间
  document.cookie = name + '=\'\'; expires=' + myDate.toGMTString()
  document.cookie = name + '=\'\'; expires=' + myDate.toGMTString() + ';path=/'
}

/**
 * @method getQueryString
 * @desc 获取链接参数的值
 * @param url {String} - 链接地址
 * @param name {String} - 参数名称
 * @returns {String} - 参数值
 */
export function getQueryString (url, name) {
  if (!url || !name) return
  return url.match(new RegExp(`(^${name}|[?|&]${name})=([^&#]+)`)) ? RegExp.$2 : ''
}

/**
 * 通用登录。登录时，直接执行回调；未登录时，拉起登录框，同时将回调注册到登录事件中
 * @method shouldLogin
 * @param {function} callback - 回调函数（第一个参数：错误信息；第二个参数：客户端登录成功返回对象，非客户端返回字符串）
 * @param {Boolean} isNative - 是否在手雷客户端
 * @example
 * shouldLogin((err, userID) => {
 *   if (!err) {
 *
 *   }
 * })
 */
export function shouldLogin (callback, isNative = IS_NATIVE, source) {
  if (typeof callback !== 'function') return

  if (isNative) {
    const callbackName = `xlGetUserInfoCallback${Date.now()}`

    window[callbackName] = (res) => {
      try {
        res = JSON.parse(res)

        if (res.isLogin) {
          callback(null, res.userInfo)
        } else {
          callback(new Error('登录失败'))
        }
      } catch (e) {
        callback(e)
      }
    }

    window.XLJSWebViewBridge.sendMessage(
      'xlGetUserInfo',
      JSON.stringify({
        forceLogin: 1,
        source
      }),
      callbackName
    )
  } else {
    if (account.isLogined()) {
      callback(null, getCookie('userid'))
    }
  }
}
// 判断移动平台（微信、QQ、微博、QQ空间、UC浏览器）
export function platform (ua = window.navigator.userAgent) {
  let platform

  if (/MicroMessenger/.test(ua)) {
    platform = 'wx'
  } else if (/QQ\//.test(ua)) {
    platform = 'qq'
  } else if (/\bWeibo|__weibo__\d/.test(ua)) {
    platform = 'weibo'
  } else if (/Qzone\//.test(ua)) {
    platform = 'qzone'
  } else if (/UCBrowser/.test(ua)) {
    platform = 'uc'
  } else {
    platform = 'other'
  }

  return platform
}

function clientType () {
  const isIOS = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
  if (isIOS) return 'ios'
  const isAndroid = /(Android)/i.test(navigator.userAgent)
  if (isAndroid) return 'android'
  return 'pc'
}
export function stat (type, action, data, sourceType, pageType) {
  window.xla && xla.push({
    type,
    category: 'page_event',
    action,
    extdata: {
      clientType: clientType(),
      sourceType: sourceType || 'resource_swap',
      pageType: pageType || 'welfare',
      ...data
    }
  })
}

/**
 * 随机数产生
 * @param {Number} length - 随机数长度
 * @param {String} [charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'] - 随机数的字符
 * @returns {String} - 随机数
 */
export function random (length, charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') {
  return Array.apply(null, new Array(length)).map(function () {
    return charset.charAt(Math.floor(Math.random() * charset.length))
  }).join('')
}

/**
 * 判断所在系统
 * @param {String} [ua = window.navigator.userAgent] - 用户代理
 * @param {String} [pf = window.navigator.platform] - 系统平台类型
 * @returns {String} os - 系统名：'iOS'|'Android'|'Windows Phone'|'macOS'|'Win2000'|'WinXP'|'Win2003'|'WinVista'|'Win7'|'Win10'|'Windows'|'Unix'|'Linux'
 */
export function detectOS (ua = window.navigator.userAgent, pf = window.navigator.platform) {
  let os = ''

  if (/iPhone|iPad|iPod|iOS/.test(ua)) {
    os = 'iOS'
  } else if (/Android/.test(ua)) {
    os = 'android'
  } else if (/\bWindows Phone/.test(ua)) {
    os = 'Windows Phone'
  } else {
    if (pf === 'Mac68K' || pf === 'MacPPC' || pf === 'Macintosh' || pf === 'MacIntel') {
      os = 'macOS'
    } else if (pf === 'Win32' || pf === 'Windows') {
      os = 'Windows'
    } else if (pf === 'X11') {
      os = 'Unix'
    } else if (/Linux/.test(pf)) {
      os = 'Linux'
    }
  }

  return os
}

/**
 * 获取 Min Max 直接的随机数字
 * @param {Number} Min
 * @param {Number} Max
 */
export function getRandomNum (Min, Max) {
  const Range = Max - Min
  const Rand = Math.random()
  return (Min + Math.round(Rand * Range))
}

/**
 * base64 编码
 * @param {String} str
 */
export function base64encode (str) {
  const base64EncodeChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
  let out, i, len
  let c1, c2, c3

  len = str.length
  i = 0
  out = ''
  while (i < len) {
    c1 = str.charCodeAt(i++) & 0xff
    if (i == len) {
      out += base64EncodeChars.charAt(c1 >> 2)
      out += base64EncodeChars.charAt((c1 & 0x3) << 4)
      out += '=='
      break
    }
    c2 = str.charCodeAt(i++)
    if (i == len) {
      out += base64EncodeChars.charAt(c1 >> 2)
      out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4))
      out += base64EncodeChars.charAt((c2 & 0xF) << 2)
      out += '='
      break
    }
    c3 = str.charCodeAt(i++)
    out += base64EncodeChars.charAt(c1 >> 2)
    out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4))
    out += base64EncodeChars.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >> 6))
    out += base64EncodeChars.charAt(c3 & 0x3F)
  }
  return out
}

/**
 *
 * @param {Number} bytes 字节数
 * @param {Number} point 小数点保留的位置
 * @param {String} unit 指定单位
 */
export function bytesToSize (bytes = 0, point = 1, unit = null) {
  if (Number(bytes) <= 0) {
    return '0 B'
  }
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = unit ? sizes.indexOf(unit) : Math.floor(Math.log(bytes) / Math.log(k))
  const val = (bytes / Math.pow(k, i))
  let size = val.toPrecision(3)
  size = val.toFixed(point)
  if (/^\d+\.(0)+$/.test(size)) {
    size = val.toFixed(0)
  }
  return size + sizes[i]
}

/**
 * @method isDebugMode
 * @desc 是否是调试模式
 * @returns {Boolean}
 */
export function isDebugMode () {
  return process.env.NODE_ENV !== 'production' || getQueryString(typeof window !== 'undefined' && window.location.href, 'debug')
}

/**
 * 时间格式化
 * @param {Date} dateObj Date对象
 * @param {string} fmt 日期格式，yyyy-MM-dd hh:mm:ss
 */
export function formatTime (dateObj, fmt) {
  const o = {
    'M+': dateObj.getMonth() + 1, // 月份
    'd+': dateObj.getDate(), // 日
    'h+': dateObj.getHours(), // 小时
    'm+': dateObj.getMinutes(), // 分
    's+': dateObj.getSeconds(), // 秒
    'q+': Math.floor((dateObj.getMonth() + 3) / 3), // 季度
    S: dateObj.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (dateObj.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (const k in o) { if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  return fmt
}

export function clientToast (message = '未知错误，请稍后再试') {
  window.XLJSWebViewBridge && window.XLJSWebViewBridge.sendMessage('xlShowToast', JSON.stringify({ message }))
}

/** 节流函数 保证在mustRun毫秒内,必然触发一次 handler */
export function throttle (func, wait, mustRun) {
  let timeout
  let startTime = new Date()
  return function () {
    const context = this
    const args = arguments
    const curTime = new Date()
    clearTimeout(timeout)
    if (curTime - startTime >= mustRun) {
      func.apply(context, args)
      startTime = curTime
    } else {
      timeout = setTimeout(func, wait)
    }
  }
}

/**
 * 从客户端内获取用户信息
 */

/**
 *
 *
 * @export
 * @param {number} millisecond 持续时间 ms
 * @returns Promise<void>
 */
export function sleep (millisecond) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, millisecond)
  })
}

export function checkIsJSON (str) {
  if (typeof str === 'string') {
    try {
      var obj = JSON.parse(str)
      if (typeof obj === 'object' && obj) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
  return false
}

export function checkIOS () {
  const UA_IOS_REG = /\b(iPad|iPhone|iPod)\b.*? OS ([\d_]+)/
  const isIOS13Pad =
    navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
  return UA_IOS_REG.test(navigator.userAgent) || isIOS13Pad
}

export function idleTimeCall (cb) {
  cb && typeof cb === 'function' && cb()
}

export function isToday (someDate) {
  someDate = new Date(someDate)
  console.log(someDate)
  const today = new Date()
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
}

export function joinParams(url, params) {
  if (!/\?/.test(url)) {
    url += "?"
  }
  const keyValues = Object.keys(params || {}).map((key) => {
    return `${key}=${escape(params[key])}`;
  });
  return url + keyValues.join("&");
}
