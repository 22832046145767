import { deleteCookie, getCookie, setCookie } from '@/utils/util'
import Config from '@/config'
import { routesConfig } from '@/utils/routes.config'

const ssoConfig = {
  APPID: 22005,
  apiOrigin: `https://${Config.userAPI === 'test' ? 'dev-' : ''}xluser-ssl.xunlei.com`,
  clientId: 'Xqp0kJBXWhwaTpB6',
  packageName: 'sj-m-ssl.xunlei.com',
  signKey: 'k&&9(rT5LU',
  algVersion: '1',
  authorizePage: `https://${Config.userAPI === 'test' ? 'dev-' : ''}i.xunlei.com/xluser/oauth.html`,
  scope: 'sso profile user',
  logLevel: '',
}

// 统一写客户端会主动调用的前端方法，可以在页面重载掉或
export default async function ({ route, store, req }) {
  const routerConfig = routesConfig.find(routeItem => routeItem.name === route.name)
  const forceLogin = routerConfig ? routerConfig.requireAuth : true
  if (!forceLogin) { return }
  const cookie =
        (process.server ? req.headers.cookie : document.cookie) || ''
  if (process.client) {
    if (store.state.isNative) {
      // 页面focus out
      window.onPause = () => {}

      // 页面focus in
      window.onResume = () => {}

      const {
        sendMessage,
        isIOSNative,
        isAndroidNative,
        isIOSXBrowserNative,
        getUserInfo,
      } = await import('@xunlei/universal-native-api')

      if (isAndroidNative || isIOSNative || isIOSXBrowserNative) {
        // 整个项目默认需要登录态，会拉起手雷的登录弹窗，可在routes.config.js中，配置无需调起手雷登录弹窗的页面
        // const forceLogin = routerConfig.requireAuth === false ? 0 : 1
        const user = await getUserInfo({ iosUserInfoScene: 'web-pan', forceLogin: 1 })
        console.log('用户信息:', user)
        // 写入/去除 cookie
        if (user.userId) {
          setCookie('userid', user.userId)
          setCookie('sessionid', user.sessionId)
          setCookie('username', user.nickname)
        } else {
          // 若退出后，需要删除cookie数据
          if (getCookie('userid', cookie)) {
            deleteCookie('userid')
            deleteCookie('sessionid')
            deleteCookie('username')
          }
          return
        }
        if (isAndroidNative) {
          const appInfo = await sendMessage({
            platform: 'android',
            name: 'xlGetAppMetaData',
          })
          console.log('手雷信息:', appInfo)
          store.commit('users/setPeerId', appInfo.deviceGuid)
          store.commit('setNativeVersion', {
            version: appInfo.versionCode,
            guid: appInfo.deviceGuid,
            deviceId: appInfo.account_device_id,
            appVersion: appInfo.appVersion,
            dl_peerId: appInfo.dl_peerId,
          })
        } else {
          store.commit('users/setPeerId', user.peerId)
          store.commit('setNativeVersion', {
            appVersion: user.appVersion,
            version: Number(user.buildVersion),
            guid: user.peerId,
          })
        }
        store.commit('users/setCurUser', {
          userId: user.userId,
          sessionId: user.sessionId,
          userName: user.nickname,
          isVip: user.isVip ? 1 : 0,
          vipType: user.vipType,
          expireTime: user.vipExpires,
          isYear: user.isYear ? 1 : 0,
          vipLevel: user.vipLevel || 0,
        })
      }

      // 安卓手雷客户端自己登录成功之后调用（不是由前端调用产生的）
      window.get_userid = (userId) => {
        if (userId) {
          androidCallUser()
        }
      }

      // 客户端点击退出键
      window.onBack = () => {}
    }
  }
  // 非端内页面,mac迅雷端内浏览器网页
  if (!store.state.isNative || /thunderbrowser/i.test(navigator.userAgent)) {
    // 只针对举报、申诉页、流量页在web端需要xbase授权
    if (['h5-report', 'h5-appeal', 'flow_privilege'].includes(route.name)) {
      const { SSO } = await import('@xbase/sso')
      const xbase = new SSO(ssoConfig)
      const isLogined = await xbase.init()
      if (isLogined) {
        const clientId = ssoConfig.clientId || ''
        try {
          const userInfo = JSON.parse(
            localStorage.getItem('credentials_' + clientId),
          )
          const { isPC, sendMessage } = await import(
            '@xunlei/universal-native-api'
          )
          if (isPC) {
            const [loginCode, pcUserId] = await sendMessage({
              platform: 'pc',
              name: 'GetUserID',
            })
            if (loginCode === 0 && pcUserId !== userInfo.user_id) {
              // 与客户端登陆态不一致时,退出登录
              return xbase.signOut(location.href)
            }
          }

          store.commit('users/setSSO', {
            userId: userInfo.user_id,
            accessToken: userInfo.access_token,
            refresh_token: userInfo.refresh_token,
          })
          const user = await xbase.auth.getUserProfile()
          // console.log('>>>> ', user)

          const vipData = {
            vipType: 0,
            isVip: 0,
            vipLevel: 0,
          }
          if (user.group && user.group.length) {
            const vids = user.group[0].id.split('_')
            vipData.isVip = Number(vids[1]) === 1
            vipData.vipType = vids[3]
            vipData.vipLevel = vids[2]
          }
          if (user.vip_info && user.vip_info.length) {
            const vipInfo = user.vip_info.filter(
              v => Number(v.vas_type) === Number(vipData.vipType),
            )
            vipData.isYear = vipInfo.length
              ? Number(vipInfo[0].is_year || 0)
              : 0
          }
          store.commit('users/setPeerId', getCookie('deviceid', cookie).split('.')[1])
          store.commit('users/setCurUser', {
            userId: userInfo.user_id,
            portrait_url: user.picture,
            nick_name: user.name,
            sessionId: getCookie('sessionid') || '',
            ...vipData,
          })
        } catch (error) {
          console.log('userInfo error : ', error)
        }
      } else {
        await xbase.signIn()
      }
    }
  }
}
