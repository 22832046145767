// result 的错误信息
const resultErrorMap = {
  'server error': '服务器异常',
  'params error': '参数错误',
  'login plz': '未登录',
  'shield review': '风险账号',
  'shield reject': '异常账号',
  'not allowed': '无权限做此操作',
  'not found': '未找到资源',
  exists: '已存在',
  'too many': '超过操作上限',
  'has voted': '你已经投过票了',
  'Network Error': '网络异常',
  offline: '网络异常，暂时无法接收哦~'
}

// detail 的错误信息
const detailErrorMap = {
  'not found circle': '未找到该圈子',
  'content too long': '超出字数限制'
}

const codeErrormap = {
  1001: '账号异常,请重新登录',
  1002: '请登录后使用',
  1003: '账号异常，请重新登录',
  1005: '该账号未实名，无法使用云盘功能',
  2001: '网络异常，无法下载',
  2004: '网络异常，请稍后再试',
  2005: '网络异常，请稍后再试',
  2006: '网络异常，请稍后再试',
  2007: '网络异常，请稍后再试',
  3001: '网络异常，请稍后再试',
  3002: '网络异常，请稍后再试',
  3003: '网络异常，请稍后再试',
  3006: '上传失败',
  3007: '链接已被举报',
  3008: '空间不足，无法保存',
  3009: '网络异常，请稍后再试',
  3010: '仅对会员用户开放',
  3011: '链接无效，保存失败',
  3012: '链接已被举报，无法保存',
  3013: '今日上传次数达到上限，请明天再来',
  3014: '链接已被举报，无法保存',
  3015: '链接已被举报，无法保存',
  3016: '链接已被举报，无法保存'
}

export function errorMap ({ result, detail, defaultError = '发生未知错误' } = {}) {
  console.log(result, resultErrorMap[result])
  let resultText = detailErrorMap[detail] || resultErrorMap[result] || codeErrormap[result]
  if (!resultText && result.includes('timeout')) {
    resultText = '请求超时'
  }
  return resultText || defaultError
}
