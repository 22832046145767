import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44d0fd32 = () => interopDefault(import('../pages/benefit/index.vue' /* webpackChunkName: "pages/benefit/index" */))
const _65e4ef40 = () => interopDefault(import('../pages/campus-partner/index.vue' /* webpackChunkName: "pages/campus-partner/index" */))
const _ce2d07ce = () => interopDefault(import('../pages/collect-fragment/index.vue' /* webpackChunkName: "pages/collect-fragment/index" */))
const _39e5f5d0 = () => interopDefault(import('../pages/download-settings/index.vue' /* webpackChunkName: "pages/download-settings/index" */))
const _d5bb3ca0 = () => interopDefault(import('../pages/drift-bottle/index.vue' /* webpackChunkName: "pages/drift-bottle/index" */))
const _1b5b0bd9 = () => interopDefault(import('../pages/drift-bottles/index.vue' /* webpackChunkName: "pages/drift-bottles/index" */))
const _f3576eea = () => interopDefault(import('../pages/feedback/index.vue' /* webpackChunkName: "pages/feedback/index" */))
const _32b06be6 = () => interopDefault(import('../pages/flow_privilege/index.vue' /* webpackChunkName: "pages/flow_privilege/index" */))
const _23fbe71d = () => interopDefault(import('../pages/group-invite/index.vue' /* webpackChunkName: "pages/group-invite/index" */))
const _5c4e5ca7 = () => interopDefault(import('../pages/history-download/index.vue' /* webpackChunkName: "pages/history-download/index" */))
const _5ecb51fa = () => interopDefault(import('../pages/middleware-page/index.vue' /* webpackChunkName: "pages/middleware-page/index" */))
const _2e7d0c00 = () => interopDefault(import('../pages/movie/index.vue' /* webpackChunkName: "pages/movie/index" */))
const _600a8083 = () => interopDefault(import('../pages/movie/index/index.vue' /* webpackChunkName: "pages/movie/index/index" */))
const _b85ec28e = () => interopDefault(import('../pages/movie/index/error.vue' /* webpackChunkName: "pages/movie/index/error" */))
const _35c4afcf = () => interopDefault(import('../pages/movie/index/_topic.vue' /* webpackChunkName: "pages/movie/index/_topic" */))
const _321e82af = () => interopDefault(import('../pages/movie-live/index.vue' /* webpackChunkName: "pages/movie-live/index" */))
const _ca3766b4 = () => interopDefault(import('../pages/pan-space/index.vue' /* webpackChunkName: "pages/pan-space/index" */))
const _7646a052 = () => interopDefault(import('../pages/red-envelope/index.vue' /* webpackChunkName: "pages/red-envelope/index" */))
const _9d7f783c = () => interopDefault(import('../pages/speedupCard/index.vue' /* webpackChunkName: "pages/speedupCard/index" */))
const _5c2f622f = () => interopDefault(import('../pages/student-privilege/index.vue' /* webpackChunkName: "pages/student-privilege/index" */))
const _6191ad45 = () => interopDefault(import('../pages/tv-input/index.vue' /* webpackChunkName: "pages/tv-input/index" */))
const _d8acd83a = () => interopDefault(import('../pages/tv-search/index.vue' /* webpackChunkName: "pages/tv-search/index" */))
const _af5c2e3a = () => interopDefault(import('../pages/activity/blue-lottery.vue' /* webpackChunkName: "pages/activity/blue-lottery" */))
const _3e6e5fe9 = () => interopDefault(import('../pages/activity/gold-lottery.vue' /* webpackChunkName: "pages/activity/gold-lottery" */))
const _8622f2da = () => interopDefault(import('../pages/activity/halloween/index.vue' /* webpackChunkName: "pages/activity/halloween/index" */))
const _2db47e96 = () => interopDefault(import('../pages/activity/red-spring.vue' /* webpackChunkName: "pages/activity/red-spring" */))
const _63d432e8 = () => interopDefault(import('../pages/activity/red-spring-share.vue' /* webpackChunkName: "pages/activity/red-spring-share" */))
const _2d647127 = () => interopDefault(import('../pages/activity/vipbenefit.vue' /* webpackChunkName: "pages/activity/vipbenefit" */))
const _254442b2 = () => interopDefault(import('../pages/benefit/activityIntro.vue' /* webpackChunkName: "pages/benefit/activityIntro" */))
const _8f5bc84e = () => interopDefault(import('../pages/benefit/eventHub.js' /* webpackChunkName: "pages/benefit/eventHub" */))
const _6c4ce45c = () => interopDefault(import('../pages/benefit/hasActivated.vue' /* webpackChunkName: "pages/benefit/hasActivated" */))
const _248c0a6f = () => interopDefault(import('../pages/benefit/receiveBenefit.vue' /* webpackChunkName: "pages/benefit/receiveBenefit" */))
const _bd077c6a = () => interopDefault(import('../pages/benefit/receivedBenefit.vue' /* webpackChunkName: "pages/benefit/receivedBenefit" */))
const _7b72ee40 = () => interopDefault(import('../pages/benefit/scaleEle.js' /* webpackChunkName: "pages/benefit/scaleEle" */))
const _d669c540 = () => interopDefault(import('../pages/benefit/stat.js' /* webpackChunkName: "pages/benefit/stat" */))
const _c171b68a = () => interopDefault(import('../pages/browser-app/feedback.vue' /* webpackChunkName: "pages/browser-app/feedback" */))
const _3e38b508 = () => interopDefault(import('../pages/browser-app/flow-privilege.vue' /* webpackChunkName: "pages/browser-app/flow-privilege" */))
const _3f4affb0 = () => interopDefault(import('../pages/browser-app/privacy-middle.vue' /* webpackChunkName: "pages/browser-app/privacy-middle" */))
const _1bb6e42a = () => interopDefault(import('../pages/campus-partner/invite.vue' /* webpackChunkName: "pages/campus-partner/invite" */))
const _d8fc9d22 = () => interopDefault(import('../pages/campus-partner/qrholder.vue' /* webpackChunkName: "pages/campus-partner/qrholder" */))
const _405cfeca = () => interopDefault(import('../pages/campus-partner/shareresult.vue' /* webpackChunkName: "pages/campus-partner/shareresult" */))
const _2ccabff6 = () => interopDefault(import('../pages/collect-fragment/rule.vue' /* webpackChunkName: "pages/collect-fragment/rule" */))
const _222dbeed = () => interopDefault(import('../pages/decompress/exceed.vue' /* webpackChunkName: "pages/decompress/exceed" */))
const _e5721eda = () => interopDefault(import('../pages/decompress/novip.vue' /* webpackChunkName: "pages/decompress/novip" */))
const _91c1c8cc = () => interopDefault(import('../pages/drift-bottle/loading.vue' /* webpackChunkName: "pages/drift-bottle/loading" */))
const _6f9e1096 = () => interopDefault(import('../pages/drift-bottle/tasklist.vue' /* webpackChunkName: "pages/drift-bottle/tasklist" */))
const _4452b0ec = () => interopDefault(import('../pages/drift-bottles/tasklist.vue' /* webpackChunkName: "pages/drift-bottles/tasklist" */))
const _552acc1e = () => interopDefault(import('../pages/h5/appeal.vue' /* webpackChunkName: "pages/h5/appeal" */))
const _5e457d3d = () => interopDefault(import('../pages/h5/assets.js' /* webpackChunkName: "pages/h5/assets" */))
const _3e609d8e = () => interopDefault(import('../pages/h5/halloween.vue' /* webpackChunkName: "pages/h5/halloween" */))
const _4593dd1a = () => interopDefault(import('../pages/h5/photo.vue' /* webpackChunkName: "pages/h5/photo" */))
const _5d8bf943 = () => interopDefault(import('../pages/h5/report.vue' /* webpackChunkName: "pages/h5/report" */))
const _0f102aff = () => interopDefault(import('../pages/history-download/utils.ts' /* webpackChunkName: "pages/history-download/utils" */))
const _93342cba = () => interopDefault(import('../pages/popup/import-record.vue' /* webpackChunkName: "pages/popup/import-record" */))
const _53d1ea10 = () => interopDefault(import('../pages/red-envelope/dialog.vue' /* webpackChunkName: "pages/red-envelope/dialog" */))
const _645b90ac = () => interopDefault(import('../pages/red-envelope/rule.vue' /* webpackChunkName: "pages/red-envelope/rule" */))
const _353e01ba = () => interopDefault(import('../pages/red-envelope/withdraw.vue' /* webpackChunkName: "pages/red-envelope/withdraw" */))
const _6979cda4 = () => interopDefault(import('../pages/activity/photoview/exam.vue' /* webpackChunkName: "pages/activity/photoview/exam" */))
const _503033d8 = () => interopDefault(import('../pages/decompress/_id/index.vue' /* webpackChunkName: "pages/decompress/_id/index" */))
const _09f4eb20 = () => interopDefault(import('../pages/decompress/_id/status.vue' /* webpackChunkName: "pages/decompress/_id/status" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/benefit",
    component: _44d0fd32,
    name: "benefit"
  }, {
    path: "/campus-partner",
    component: _65e4ef40,
    name: "campus-partner"
  }, {
    path: "/collect-fragment",
    component: _ce2d07ce,
    name: "collect-fragment"
  }, {
    path: "/download-settings",
    component: _39e5f5d0,
    name: "download-settings"
  }, {
    path: "/drift-bottle",
    component: _d5bb3ca0,
    name: "drift-bottle"
  }, {
    path: "/drift-bottles",
    component: _1b5b0bd9,
    name: "drift-bottles"
  }, {
    path: "/feedback",
    component: _f3576eea,
    name: "feedback"
  }, {
    path: "/flow_privilege",
    component: _32b06be6,
    name: "flow_privilege"
  }, {
    path: "/group-invite",
    component: _23fbe71d,
    name: "group-invite"
  }, {
    path: "/history-download",
    component: _5c4e5ca7,
    name: "history-download"
  }, {
    path: "/middleware-page",
    component: _5ecb51fa,
    name: "middleware-page"
  }, {
    path: "/movie",
    component: _2e7d0c00,
    children: [{
      path: "",
      component: _600a8083,
      name: "movie-index"
    }, {
      path: "error",
      component: _b85ec28e,
      name: "movie-index-error"
    }, {
      path: ":topic",
      component: _35c4afcf,
      name: "movie-index-topic"
    }]
  }, {
    path: "/movie-live",
    component: _321e82af,
    name: "movie-live"
  }, {
    path: "/pan-space",
    component: _ca3766b4,
    name: "pan-space"
  }, {
    path: "/red-envelope",
    component: _7646a052,
    name: "red-envelope"
  }, {
    path: "/speedupCard",
    component: _9d7f783c,
    name: "speedupCard"
  }, {
    path: "/student-privilege",
    component: _5c2f622f,
    name: "student-privilege"
  }, {
    path: "/tv-input",
    component: _6191ad45,
    name: "tv-input"
  }, {
    path: "/tv-search",
    component: _d8acd83a,
    name: "tv-search"
  }, {
    path: "/activity/blue-lottery",
    component: _af5c2e3a,
    name: "activity-blue-lottery"
  }, {
    path: "/activity/gold-lottery",
    component: _3e6e5fe9,
    name: "activity-gold-lottery"
  }, {
    path: "/activity/halloween",
    component: _8622f2da,
    name: "activity-halloween"
  }, {
    path: "/activity/red-spring",
    component: _2db47e96,
    name: "activity-red-spring"
  }, {
    path: "/activity/red-spring-share",
    component: _63d432e8,
    name: "activity-red-spring-share"
  }, {
    path: "/activity/vipbenefit",
    component: _2d647127,
    name: "activity-vipbenefit"
  }, {
    path: "/benefit/activityIntro",
    component: _254442b2,
    name: "benefit-activityIntro"
  }, {
    path: "/benefit/eventHub",
    component: _8f5bc84e,
    name: "benefit-eventHub"
  }, {
    path: "/benefit/hasActivated",
    component: _6c4ce45c,
    name: "benefit-hasActivated"
  }, {
    path: "/benefit/receiveBenefit",
    component: _248c0a6f,
    name: "benefit-receiveBenefit"
  }, {
    path: "/benefit/receivedBenefit",
    component: _bd077c6a,
    name: "benefit-receivedBenefit"
  }, {
    path: "/benefit/scaleEle",
    component: _7b72ee40,
    name: "benefit-scaleEle"
  }, {
    path: "/benefit/stat",
    component: _d669c540,
    name: "benefit-stat"
  }, {
    path: "/browser-app/feedback",
    component: _c171b68a,
    name: "browser-app-feedback"
  }, {
    path: "/browser-app/flow-privilege",
    component: _3e38b508,
    name: "browser-app-flow-privilege"
  }, {
    path: "/browser-app/privacy-middle",
    component: _3f4affb0,
    name: "browser-app-privacy-middle"
  }, {
    path: "/campus-partner/invite",
    component: _1bb6e42a,
    name: "campus-partner-invite"
  }, {
    path: "/campus-partner/qrholder",
    component: _d8fc9d22,
    name: "campus-partner-qrholder"
  }, {
    path: "/campus-partner/shareresult",
    component: _405cfeca,
    name: "campus-partner-shareresult"
  }, {
    path: "/collect-fragment/rule",
    component: _2ccabff6,
    name: "collect-fragment-rule"
  }, {
    path: "/decompress/exceed",
    component: _222dbeed,
    name: "decompress-exceed"
  }, {
    path: "/decompress/novip",
    component: _e5721eda,
    name: "decompress-novip"
  }, {
    path: "/drift-bottle/loading",
    component: _91c1c8cc,
    name: "drift-bottle-loading"
  }, {
    path: "/drift-bottle/tasklist",
    component: _6f9e1096,
    name: "drift-bottle-tasklist"
  }, {
    path: "/drift-bottles/tasklist",
    component: _4452b0ec,
    name: "drift-bottles-tasklist"
  }, {
    path: "/h5/appeal",
    component: _552acc1e,
    name: "h5-appeal"
  }, {
    path: "/h5/assets",
    component: _5e457d3d,
    name: "h5-assets"
  }, {
    path: "/h5/halloween",
    component: _3e609d8e,
    name: "h5-halloween"
  }, {
    path: "/h5/photo",
    component: _4593dd1a,
    name: "h5-photo"
  }, {
    path: "/h5/report",
    component: _5d8bf943,
    name: "h5-report"
  }, {
    path: "/history-download/utils",
    component: _0f102aff,
    name: "history-download-utils"
  }, {
    path: "/popup/import-record",
    component: _93342cba,
    name: "popup-import-record"
  }, {
    path: "/red-envelope/dialog",
    component: _53d1ea10,
    name: "red-envelope-dialog"
  }, {
    path: "/red-envelope/rule",
    component: _645b90ac,
    name: "red-envelope-rule"
  }, {
    path: "/red-envelope/withdraw",
    component: _353e01ba,
    name: "red-envelope-withdraw"
  }, {
    path: "/activity/photoview/exam",
    component: _6979cda4,
    name: "activity-photoview-exam"
  }, {
    path: "/decompress/:id",
    component: _503033d8,
    name: "decompress-id"
  }, {
    path: "/decompress/:id?/status",
    component: _09f4eb20,
    name: "decompress-id-status"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
