/**
 * 下载记录相关请求
 */
import axios from 'axios'
import md5 from 'blueimp-md5'
import { getRandomNum, getCookie, base64encode } from '@/utils/util.js'
import { fetchDataWithShoulei } from '@/api/fetch-res'
import getUserInfo from '@/utils/get-user-info'
import config from '@/config.js'

const baseUrl = {
  prod: 'https://api-zone-lixian-vip-ssl.xunlei.com',
  test: 'https://xllixian.office.k8s.xunlei.cn'
}[config.shouleiAPI]
const vipInfoUrl = 'https://vipcache-zone-lixian-vip-ssl.xunlei.com'
/**
 * 获取等级对应的喜欢数，同步数限制
 */
export function getRecordConfig () {
  const uid = getCookie('userid') || '0'
  return fetchRecod(`${baseUrl}/get_copywriting_config/${uid}`, 'Get', {}, {
    origin: 'lixian_download',
    device_id: ''
  })
}

/**
 * 获取 vip 状态信息
 * @param {String} userid
 */
export function getVipState (userid) {
  const params = Object.assign({ user_id: userid }, getCommonData('get'))
  return fetchRecod(`${vipInfoUrl}/tasks/query_vip_information`, 'get', {}, params)
}

/**
 * 拉取历史下载记录列表
 * 接口 wiki： http://wiki.xunlei.cn/pages/viewpage.action?pageId=20873184
 * @param {Object} param0
 */
export function getDownloadTask ({ cursor, pageSize, filetype, recordType, userId, deviceId, is_get_history_total = 1, filter_drive_task = 0 }) {
  const uid = userId || getCookie('userid') || '0'
  return fetchRecod(`${baseUrl}/tasks/${uid}`, 'get', {}, {
    begin: cursor,
    num: pageSize,
    filetype: filetype || '0',
    record_type: recordType || '0',
    device_id: deviceId,
    is_get_history_total, // 可不填，不填默认为1，0表示不获取，1表示获取（针对云盘中台服务新增）
    filter_drive_task // 可不填，不填默认为0，0表示获取全部任务，1表示仅查询来源于云盘的记录，2表示仅查询来源于非云盘的记录
  })
}

/**
 * 获取喜欢的资源
 * @param {Object} param0
 */
export function getMyFavTask ({ cursor, pageSize, filetype, folderId }) {
  const uid = getCookie('userid') || '0'
  return fetchRecod(`${baseUrl}/collections/${uid}`, 'get', {}, {
    begin: cursor,
    num: pageSize,
    filetype: filetype || '0',
    folder_id: folderId || '0',
    origin: 'lixian_download'
  })
}

/**
 * 添加下载记录到喜欢
 * @param {Object} param0
 */
export function collectTask ({ folderId, taskIds, recordType }) {
  if (!Array.isArray(taskIds)) {
    taskIds = [taskIds]
  }
  const taskInfos = taskIds.map((taskId) => {
    return {
      task_id: taskId,
      folder_id: folderId || '1000',
      record_type: recordType || '0'
    }
  })
  const uid = getCookie('userid') || '0'
  return fetchRecod(`${baseUrl}/collections/${uid}`, 'post', {
    task_info: taskInfos
  })
}

/**
 * 取消收藏任务
 * @param {String} collectId
 */
export function cancelCollect (collectId) {
  const uid = getCookie('userid') || '0'
  return fetchRecod(`${baseUrl}/collections/${uid}/${collectId}`, 'delete')
}

/**
 * 删除任务
 * @param {Array} taskIds
 */
export function deleteTask (taskIds, deviceId) {
  if (!Array.isArray(taskIds)) {
    taskIds = [taskIds]
  }
  const paramStr = taskIds.join(',')
  const uid = getCookie('userid') || '0'
  return fetchRecod(`${baseUrl}/tasks/${uid}/${paramStr}`, 'delete', {}, {
    device_id: deviceId
  })
}

export function deleteAllTask (deviceId) { // 删除全部资源
  const uid = Number(getCookie('userid')) || 0
  const param = getCommonData('POST')
  return fetchRecod(`${baseUrl}/tasks:deleteAll`, 'POST', {
    uid: uid,
    device_id: deviceId,
    ...param
  })
}

export function syncDeviceTaskToUser ({ userId, deviceId }) {
  return fetchRecod(`${baseUrl}/synchroniza/${userId}`, 'POST', {}, {
    device_id: deviceId
  })
}
// wiki地址：http://wiki.xunlei.cn/pages/viewpage.action?pageId=20873184
function getCommonData (method) {
  const secret = '!@#$%^&*()QAZ'
  const commonData = {
    client_name: 'xl_android_web',
    client_version: 0,
    ts: (new Date()).getTime(),
    r: `${getRandomNum(1, 100)}`
  }
  const str = `${secret}${method.toUpperCase()}${commonData.client_name}${commonData.client_version}${commonData.ts}${commonData.r}`
  commonData.key = md5(str)
  return commonData
}

/**
 *
 * @param {Array} 任务数组
 */
export function getTasksSensitive ({ tasks }) {
  const typeMap = {
    4: 'emule',
    5: 'bt'
  }
  const taskInfos = tasks.map((task) => {
    const url = (task.url || '')
    return {
      type: typeMap[task.url_type] || 'url',
      res: typeMap[task.url_type] === 'bt' ? (url.split('//')[1] || url.split('btih:')[1] || '') : task.url
    }
  })
  return fetchDataWithShoulei('/xlppc.resinfo.api/v2/query_sensitive_level', {
    query_tasks: taskInfos,
    _m: 'post'
  })
}

/**
 *
 * @param {String} url
 * @param {String} method
 * @param {Object} data
 * @param {Object} params
 */
async function fetchRecod (url, method, data = {}, param) {
  const userInfo = await getUserInfo()
  // console.log('>>> userInfo: ', userInfo)
  // debugger
  const params = Object.assign({}, getCommonData(method), param)
  return axios({
    method,
    url,
    params,
    data,
    headers: {
      Accept: 'application/json; version=1.0',
      Authorization: 'Basic ' + getBase64Code({ userId: userInfo[1], sessionId: userInfo[3] })
    }
  }).then((res) => {
    if (res && res.data && res.data.result === 0) {
      return res.data
    } else {
      return Promise.reject(res.data)
    }
  })
}

function getBase64Code ({ sessionId, userId }) {
  return base64encode(
    (userId || getCookie('userid') || '0') +
    ':' +
    (sessionId || getCookie('sessionid') || '-') +
    ':web:-1')
}
