// 默认每个路由需要强制登录
// requireAuth === false 为不需要登录态
export const routesConfig = [{
  name: 'download-settings',
  requireAuth: false,
},
{
  name: 'browser-app-privacy-middle',
  requireAuth: false,
},
{
  name: 'browser-app-flow-privilege',
  requireAuth: false,
},
{
  name: 'browser-app-feedback',
  requireAuth: false,
},
]
