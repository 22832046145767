import { getCookie, setCookie } from '@/utils/util'
const client = process.client ? (() => require('@/utils/clientApi').default)() : null

const commonApi = {
  isLogined () {
    const userid = getCookie('userid')
    const sessionId = getCookie('sessionid')

    return userid && userid !== '0' && sessionId
  },
  async login () {
    return new Promise((resolve, reject) => {
      this.onceUserLogin(() => {
        resolve(getCookie('userid'))
      })
    })
  },
  onceUserLogin (callback) {
    if (typeof callback === 'function') {
      const eventId = this.onUserLogin(() => {
        callback()
        this.offUserLogin(function () { }, eventId)
      })
    }
  }
}
const account = Object.assign(  commonApi)
export default account

export async function iosGetUserInfo (store) {
  const [err, user] = await client.callNativeHandler('userInfo')
  if (err) return
  console.log('ios user', user)
  store.commit('users/setCurUser', {
    appid: user.appId,
    authorize: user.authorize,
    userId: user.userId,
    sessionId: user.sessionId,
    userName: user.nickName,
    isVip: user.isVip,
    vipType: user.vipType,
    deviceId: user.deviceId,
    peerId: user.peerId
  })
  store.commit('setNativeVersion', {
    appVersion: user.appVersion,
    version: user.buildVersion,
    guid: user.peerId
  })
  setCookie('userid', user.userId)
  setCookie('sessionid', user.sessionId)
  setCookie('username', user.nickName)
  setCookie('peerid', user.peerId)
}
