import { getCashGrades, getWithdrawTotalNum, getCashHistory } from '@/api/red-envelope'
import sget from '@xunlei/sget'
import Vue from 'vue'

export const state = () => ({
  cashGrades: {},
  withdrawNum: {},
  withdrawHistory: {}
})

export const mutations = {
  setCashGrades (state, data) {
    Vue.set(state, 'cashGrades', data)
  },
  setWithdrawNum (state, data) {
    Vue.set(state, 'withdrawNum', data)
  },
  setWithdrawHistory (state, data) {
    let cash_history = data.cursor !== 0 ? [...(state.withdrawHistory.cash_history || []), ...data.cash_history] : data.cash_history
    Vue.set(state, 'withdrawHistory', Object.assign(data, { cash_history }))
  }
}

export const actions = {
  getCashGrades ({ commit }) {
    return getCashGrades()
      .then(res => {
        if (res.result === 'ok') {
          commit('setCashGrades', res.data)
        } else {
          throw new Error()
        }
      })
  },
  getWithdrawNum ({ commit }) {
    return getWithdrawTotalNum()
      .then(res => {
        if (res.result === 'ok') {
          commit('setWithdrawNum', res.data)
        }
      })
  },
  getCashHistory ({ state, commit }, { cursor = state.withdrawHistory.last_cursor, size = 10 } = {}) {
    return getCashHistory({ cursor, size })
      .then(res => {
        if (res.result === 'ok') {
          commit('setWithdrawHistory', {
            cursor,
            ...res.data
          })
        } else {
          throw new Error()
        }
      })
  }
}