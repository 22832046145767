import Vue from 'vue'
import sget from '@xunlei/sget'
import { getUserInfo } from '@/api/index'
import { getVipState } from '@/api/task-record.js'
export const state = () => ({
  curUser: {
    userId: '0',
    vipType: 0,
  }, // 当前登录用户
  sso: null,
  all: {} // 所有用户
})

export const mutations = {
  // 设置当前登录用户
  // vipType 表示的是上一次用户的会员身份, 需要和isVip联合起来判断
  setCurUser (state, user) {
    if (!(user && user.userId)) {
      user.userId = '0'
    }
    Object.assign(user, { userId: String(user.userId) })
    state.curUser = Object.assign({}, state.curUser, user)
  },
  setPeerId (state, peerId) {
    state.curUser = Object.assign({}, state.curUser, { peerId })
  },
  expendCurUser (state, user) {
    // 丰富当前用户字段
    state.curUser = Object.assign({}, state.curUser, user)
  },
  setCurUserVipType (state, { type, expireTime }) {
    state.curUser = Object.assign({}, state.curUser, {
      vipType: type,
      expireTime
    })
  },
  setSSO (state, sso) {
    Vue.set(state, 'sso', Object.freeze(sso))
  }
}

export const actions = {
  getUserVipState ({ commit }, { userId }) {
    return getVipState(userId)
      .then((res) => {
        commit('setCurUserVipType', {
          type: sget(res.task_info, 'vas_type'),
          expireTime: sget(res.task_info, 'expire_time')
        })
      })
  },

  async getUserVipInfo ({ commit }) {
    const {
      getUserInfo,
    } = await import('@xunlei/universal-native-api')
    getUserInfo().then((user) => {
      commit('setCurUser', {
        userId: user.userId,
        sessionId: user.sessionId,
        userName: user.nickname,
        isVip: user.isVip ? 1 : 0,
        vipType: user.vipType,
        expireTime: user.vipExpires,
        isYear: user.isYear ? 1 : 0,
      })
    })
  },
  getUserInfo ({ commit }, userId) {
    return getUserInfo(userId)
      .then(res => {
        if (res.result !== 'ok') throw new Error()
        commit('expendCurUser', {
          nick_name: res.user_info.nick_name,
          portrait_url: res.user_info.portrait_url,
          vip_extra: res.user_info.vip_extra
        })
      })
  },
  async signOut ({ commit, state }, url) {
    // debugger
    return await state.sso.signOut(url)
  }
}
