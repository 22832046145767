import api from '@/api'
export const state = () => ({
  wechatConfig: {},
  isNative: false, // 是否是手雷客户端内
  native: {
    version: '0', // 客户端版本
    dl_peerId: '',
    guid: '',
    deviceId: '',
    os: ''
  },
  platform: '',
  ua: ''
})

export const mutations = {
  setWechatConfig (state, config) {
    state.wechatConfig = Object.freeze(config)
  },
  setNativeVersion (state, info) {
    state.native = { ...state.native, ...info }
  },
  setIsNative (state, isNative) {
    state.isNative = isNative
  },
  setPlatform (state, platform) {
    state.platform = platform
  },
  setOs (state, os) {
    state.native.os = os
  },
  setUa (state, ua) {
    state.ua = ua
  }
}

export const actions = {
  getWechatConfig ({ commit }, { url }) {
    return api.getWechatConfig({url})
      .then((res) => {
        if (res.data.code === 200) {
          commit('setWechatConfig', res.data.data)
          return 'success'
        }
      })
  }
}
