import axios from 'axios'
import { fetchJSONP, fetchSlRes, fetchDataWithShoulei, fetchJsonpWithUser, SHOULEI_API } from '@/api/fetch-res'
import { requestShoulei as fetchNative } from '@/api/fetch-native'
import config from '@/config'

export default {
  getWechatConfig ({ url }) {
    return axios({
      url: `https://weixinapi-m-ssl.xunlei.com/wx/sign/js_api?page_url=${url || location.href}`,
      method: 'GET'
    })
  }
}

// 查询账号是否绑定公众号
export function isBindWechat () {
  return fetchJSONP('https://dyactive2-vip-ssl.xunlei.com/iface/?action=checkBind&actid=vip2019halloween', {
    callbackName: 'xl_check_wecath_bind'
  })
}

// 获取用户信息
export function getUserInfo (uid) {
  return fetchSlRes('/user_info/pc_info', { uid })
}

// 获取用户实名认证信息
export function getUserCretInfo ({
  authType,
  userId,
  sessionId,
  deviceId
}) {
  return axios({
    url: `https://xluser-ssl.xunlei.com/certification/v1.3/isauth?authType=${authType}&auth.userid=${userId}&sessionid=${sessionId}&deviceid=${deviceId}`,
    method: 'GET'
  })
}

// 根据链接生成口令
export function createBirdKey ({ uid, urls, type = 'common' }) {
  let url = urls
  if (Array.isArray(urls)) {
    url = urls.join('\n')
  }
  return fetchSlRes('/dlj_create', {
    _m: 'post',
    url,
    uid,
    type
  })
}

// 发送短信
export function sendSmsToMobile (qs) {
  // 要求校验cookie的sessionid登陆态
  return fetchJsonpWithUser('/msg/v1/SendSmsToMobile', {
    qs
  })
}

// 校验短信
export function checkSmsToMobile (qs) {
  return fetchJsonpWithUser('/msg/v1/CheckSmsToMobile', {
    qs
  })
}

// 云盘举报
export function reportOffense (params) {
  return fetchDataWithShoulei('/xlppc.report.offense/report_offense', params, {
    method: 'POST'
  })
}

// 云盘申诉
export function reportAppeal (params) {
  return fetchDataWithShoulei('/xlppc.report.offense/appeal', params, {
    method: 'POST'
  })
}

/** 下载申诉 */
export function reportAppealOs (params, headers) {
  return axios({
    url: 'https://2rvk4e3gkdnl7u1kl0k.xbase.cloud/fn/statappeal/5yrcemb',
    method: 'POST',
    data: params,
    headers
  })
}

// http://test.api-shoulei-ssl.xunlei.com/activity/swagger/docs#/ActivityService/ActivityService_GrantReward
// 在线解压看广告活动
export function fetchReward (params, options) {
  return fetchNative('/activity/v1/reward', params, options)
}
// 领取
export function postReward (params, options) {
  return fetchNative('/activity/v1/reward', params, { ...options, method: 'POST' })
}

// 查询群组信息: http://wiki.xunlei.cn/pages/viewpage.action?pageId=50889984#id-%E7%BE%A4%E7%AE%A1%E7%90%86-%E6%9F%A5%E8%AF%A2%E7%BE%A4%E4%BF%A1%E6%81%AF/chitchat/v1/group/queryPOST
export function postGroupQuery (params) {
  return axios({
    url: SHOULEI_API[config.shouleiAPI] + '/chitchat/v1/group/query',
    method: 'POST',
    data: params
  })
}
