import {
  getRecordConfig,
  getDownloadTask,
  getMyFavTask,
  collectTask,
  cancelCollect,
  deleteTask,
  syncDeviceTaskToUser,
  getTasksSensitive,
  deleteAllTask
} from '@/api/task-record.js'
import Vue from 'vue'
import sget from '@xunlei/sget'
import { createBirdKey } from '@/api/index'
import { getCookie } from '@/utils/util.js'
import find from 'lodash/find'
export const state = () => ({
  recordeLimit: {
    collectNum: {}, // 收藏资源个数限制
    saveTime: {}, // 保存资源记录时间限制
    validTime: {}, // 资源有效期限制
    config: {} // 三种限制中时间对应的毫秒数
  },
  tasks: {}, // {'userId': taskObj}
  favTask: {}, // 喜欢的资源 {'userId': taskObj}
  taskSensitiveLevel: {}, // 敏感资源列表 {'userId': { taskId : 0/1/2}} http://wiki.xunlei.cn/pages/viewpage.action?pageId=36756427
  birdKeyObj: {} // 任务对应的口令 {taskId: key}
})

export const mutations = {
  // 设置时间限制
  setRecordLimit (state, { collectNum, saveTime, validTime, config }) {
    state.recordeLimit = {
      collectNum,
      saveTime,
      validTime,
      config
    }
  },
  // 设置下载记录 Tab 列表
  setTasks (state, { userId, tasks, cursor, total, hasMore = true }) {
    if (cursor === 0) {
      Vue.set(state.tasks, userId, {
        data: tasks,
        total,
        hasMore
      })
    } else {
      const data = sget(state.tasks, userId, 'data') || []
      Vue.set(state.tasks, userId, {
        data: [...data, ...tasks],
        total,
        hasMore
      })
    }
  },
  // 设置我的收藏 Tab
  setFavTasks (state, { userId, tasks, cursor, total }) {
    if (cursor === 0) {
      Vue.set(state.favTask, userId, {
        data: tasks,
        total
      })
    } else {
      const data = sget(state.favTask, userId, 'data') || []
      Vue.set(state.favTask, userId, {
        data: [...data, ...tasks],
        total
      })
    }
  },
  // 更改资源喜欢的状态
  changeTask (state, { userId, taskIndex, task }) {
    const data = sget(state.tasks, userId, 'data') || []
    const total = sget(state.tasks, userId, 'total')
    if (!data.length) return
    data.forEach(item => {
      if (item.task_id === task.task_id) {
        item.collection_id = task.collection_id
      }
    })
    // data.splice(taskIndex, 1, task) // 更改下载记录资源 tab
    state.tasks[userId] = {
      data,
      total
    }
  },
  // 添加一个喜欢的资源
  addFavTask (state, { userId, task }) {
    const data = sget(state.favTask, userId, 'data')
    const total = sget(state.favTask, userId, 'total')
    if (data === undefined) { // 未加载过喜欢得资源
      return
    }
    Vue.set(state.favTask, userId, {
      total: total + 1,
      data: [task, ...data]
    })
  },
  // 取消喜欢
  deleteFavTask (state, { userId, taskIndex, taskId }) {
    const data = sget(state.favTask, userId, 'data') || []
    const total = sget(state.favTask, userId, 'total')
    let index = taskIndex
    const allData = sget(state.tasks, userId, 'data') || []
    const allTotal = sget(state.tasks, userId, 'total')
    allData.forEach(item => {
      if (taskId.includes(item.task_id)) {
        item.collection_id = ''
      }
    })
    // 对所有资源tab值处理
    state.tasks[userId] = {
      data: allData,
      total: allTotal
    }
    taskId.forEach((itemOut) => {
      if (index === -1) {
        index = data.findIndex((itemIn) => {
          return sget(itemIn, 'task_id') === itemOut
        })
      }
      if (data.length) {
        data.splice(index, 1)
        Vue.set(state.favTask, userId, {
          total: total - 1,
          data
        })
      }
    })
  },

  // 删除一个普通资源
  deleteNormalTask (state, { userId, taskIndex, taskId, isDeleteAll = false }) {
    const data = sget(state.tasks, userId, 'data') || []
    const total = sget(state.tasks, userId, 'total')
    if (isDeleteAll) { // 删除全部
      Vue.set(state.tasks, userId, {
        total: 0,
        data: []
      })
      return
    }

    let index = taskIndex
    if (index === -1) {
      index = data.findIndex((item) => {
        return sget(item, 'task_id') === taskId
      })
    }
    if (index !== -1) {
      data.splice(index, 1)
      Vue.set(state.tasks, userId, {
        total: total - 1,
        data
      })
    }
  },
  // 添加敏感资源列表
  addSensitiveTask (state, { userId, taskId, level }) {
    const data = state.taskSensitiveLevel[userId] || {}
    if (data[taskId] === undefined) {
      data[taskId] = level
    }
    Vue.set(state.taskSensitiveLevel, userId, data)
  },

  // 设置口令
  setBirdKey (state, { taskId, birdKey }) {
    if (state.birdKeyObj[taskId]) {
      return
    }
    state.birdKeyObj[taskId] = birdKey
  }
}

export const actions = {
  getrecordeLimit ({ commit }) {
    return getRecordConfig()
      .then((res) => {
        commit('setRecordLimit', {
          collectNum: res.collection_num_limit,
          saveTime: res.save_time,
          validTime: res.valid_time,
          config: res.config
        })
      })
      .catch((e) => {
        return Promise.reject(new Error(e))
      })
  },
  getTaskAll ({ commit, state }, { cursor, pageSize, userId, deviceId, type = 0 }) {
    if (userId === '0' && !deviceId) {
      return Promise.resolve()
    }
    return getDownloadTask({
      cursor,
      pageSize,
      userId,
      deviceId,
      filter_drive_task: type // 0为全部，1为云盘添加任务，2为下载任务
    }).then((res) => {
      const cur = cursor || 0
      commit('setTasks', {
        userId,
        cursor: cur,
        tasks: res.task_info,
        total: res.total,
        hasMore: res.task_info.length > 0
      })
      return res.total
    })
      .catch((e) => {
        return Promise.reject()
      })
  },

  getFavTask ({ commit, state }, { cursor, pageSize }) {
    return getMyFavTask({
      cursor,
      pageSize
    }).then((res) => {
      const userId = getCookie('userid') || '0'
      const cur = cursor || 0
      commit('setFavTasks', {
        userId,
        cursor: cur,
        tasks: res.task_info,
        total: res.total
      })
      return true
    })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  collectTask ({ commit }, { taskId, taskIndex, taskInfo, commitName = 'changeTask' }) {
    const userId = getCookie('userid') || '0'
    return collectTask({ taskIds: [taskId] })
      .then((res) => {
        const task = Object.assign({}, taskInfo, (sget(res, 'task_info') || [])[0])
        commit(commitName, {
          userId,
          taskIndex,
          task
        })
        commit('addFavTask', {
          userId,
          task
        })
        return true
      })
      .catch((e) => {
        return Promise.reject()
      })
  },
  cancelCollectTask ({ commit, state }, { task, tasks = [] }) {
    const userId = getCookie('userid') || '0'
    let collectId = sget(task, 'collection_id')
    let taskId = [sget(task, 'task_id')]
    if (tasks.length > 0) { // 是否是多选取消喜欢
      const collectIds = tasks.map((item) => {
        return sget(item, 'collection_id')
      })
      const taskIds = tasks.map((item) => {
        return sget(item, 'task_id')
      })
      collectId = collectIds.join(',')
      taskId = taskIds
    }
    return cancelCollect(collectId)
      .then(() => {
        commit('deleteFavTask', {
          userId,
          taskIndex: -1,
          taskId: taskId
        })
      })
      .catch((e) => {
        return Promise.reject(new Error(e))
      })
  },

  deleleTask ({ commit, state }, { taskId, taskIndex, deviceId, commitName = 'deleteNormalTask' }) {
    const userId = getCookie('userid') || '0'
    return deleteTask(taskId, deviceId)
      .then(() => {
        commit(commitName, {
          userId,
          taskIndex,
          taskId
        })
      })
      .catch((e) => {
        return Promise.reject(new Error(e))
      })
  },
  // 批量删除任务
  batchDeleteTask ({ commit }, { taskIds, deviceId, commitName = 'deleteNormalTask' }) {
    return deleteTask(taskIds, deviceId)
      .then(() => {
        if (commitName) {
          const userId = getCookie('userid') || '0'
          for (const taskId of taskIds) {
            commit(commitName, {
              userId,
              taskIndex: -1,
              taskId
            })
          }
        }
      })
      .catch((e) => {
        return Promise.reject(new Error(e))
      })
  },

  async deleteAllTask ({ commit }, { deviceId }) {
    const userId = getCookie('userid') || '0'
    await deleteAllTask(deviceId)
    commit('deleteNormalTask', {
      userId,
      isDeleteAll: true
    })
  },

  // 批量添加喜欢任务
  async batchCollectTask ({ state, commit }, { taskIds, tasks, commitName = 'changeTask' }) {
    if (!taskIds.length) return Promise.resolve()
    const res = await collectTask({ taskIds })
    if (commitName && tasks) {
      const userId = getCookie('userid') || '0'
      for (let task of tasks) {
        task = Object.assign({}, task, find(sget(res, 'task_info') || [], el => el.task_id === task.task_id))
        commit(commitName, {
          userId,
          taskIndex: -1,
          task
        })
      }
    }
  },
  syncTaskToUser ({ commit }, { userId, deviceId }) {
    return syncDeviceTaskToUser({
      userId,
      deviceId
    }).catch((e) => {
      return Promise.reject(new Error(e))
    })
  },
  // 处理敏感资源
  getTaskIsSensitive ({ commit, state }, { tasks, userId }) {
    // 过滤已经存在的资源
    const senSitiveData = sget(state.taskSensitiveLevel, userId) || {}
    const _tasks = tasks.filter((task) => {
      return senSitiveData[sget(task, 'task_id')] === undefined
    })
    if (!_tasks.length) return Promise.resolve()

    return getTasksSensitive({
      tasks: _tasks
    }).then((res) => {
      const data = sget(res, 'data', 'sensitive_level_results') || []
      data.forEach((item, index) => {
        const taskId = sget(_tasks[index], 'task_id')
        if (sget(state.taskSensitiveLevel, userId, taskId) === undefined) {
          commit('addSensitiveTask', {
            userId,
            taskId,
            level: item.sensitive_level
          })
        }
      })
    }).catch((e) => {
      _tasks.forEach((task) => { // 失败的情况下默认 2 等级
        const taskId = sget(task, 'task_id')
        commit('addSensitiveTask', {
          userId,
          taskId,
          level: 2
        })
      })
    })
  },

  // 获取迅雷口令
  getTaskBirdKey ({ commit, state }, { task, uid }) {
    // 获取口令没生成的任务
    if (state.birdKeyObj[sget(task, 'task_id')]) return Promise.resolve()
    return createBirdKey({
      uid,
      urls: this.$sget(task, 'url')
    }).then((res) => {
      if (sget(res, 'result') === 'ok') {
        commit('setBirdKey', {
          taskId: sget(task, 'task_id'),
          birdKey: this.$sget(res, 'bird_key')
        })
      }
    })
  }
}
