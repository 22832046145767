import { SHOULEI_API } from '@/api/fetch-res'
import { DRIVE_API } from '@/api/fetch-pan'
import qs from 'qs'
import config from '@/config'

let fetchNative = null
let isAndroid = true

if (process.browser) {
  fetchNative = (() => require('@xunlei/universal-native-api').request)()
  isAndroid = (() => require('@xunlei/universal-native-api').isAndroidDevice)()
}

export async function request (url, params, options = {}) {
  console.log('fetch native', url, params, options)
  const method = options.method || 'GET'
  if (params.filters) {
    params.filters = JSON.stringify(params.filters)
  }
  if (method === 'GET' && isAndroid) {
    // 在安卓手雷端，如果GET请求某参数为数组形式，需要提前处理好拼接到url上; ios则正常且不能提前拼接url
    let str = qs.stringify(params)
    if (/batchGet/.test(url)) {
      str = qs.stringify(params, { arrayFormat: 'repeat' })
    }
    if (params.with_quotas) {
      str = qs.stringify(params, { arrayFormat: 'repeat' })
    }
    params = {}
    // 兼容url主动添加参数的情况
    url = url + (str ? '?' + str : '')
  }
  const [err, res] = await fetchNative(url, params, options)
  console.log('请求接口', err, '成功', res)
  if (err) {
    // TODO: 注意，安卓请求失败时包了一层处理
    if (!isAndroid) return Promise.reject(err)
    try {
      return Promise.reject(JSON.parse(err.body))
    } catch (error) {
      return Promise.reject(err)
    }
  }
  return res
}

export function requestShoulei (url, params, option = {}) {
  url = option.env ? `${SHOULEI_API[option.env]}${url}` : `${SHOULEI_API[config.shouleiAPI]}${url}`
  return request(url, params, option)
}

export function requestPan (url, params, option = {}) {
  // http://drive.office.k8s.xunlei.cn/?urls.primaryName=file.swagger.json
  url = option.env ? `${DRIVE_API[option.env]}${url}` : `${DRIVE_API[config.driveAPI]}${url}`
  return request(url, params, option)
}

export function requestAd (url, params, option = {}) {
  // http://wiki.xunlei.cn/pages/viewpage.action?pageId=33341359
  url = `https://advertpay-vip-ssl.xunlei.com${url}`
  return request(url, params, option)
}

export function requestHttp (url, params, option = {}) {
  if (!/^https?:\/\//.test(url)) {
    return Promise.reject({
      error: 'error',
      error_code: -1,
      error_description: '非有效链接',
    })
  }

  return request(url, params, option)
}
