<template>
  <div>
    <nuxt />
  </div>
</template>
<script>
export default {
  head () {
    return {
      // 切换layout会导致 xla.push 执行新的config，自测没有问题，但是 @康凯（已离职）建议用额外的一个xla镜像库来同时存在2个全局变量
      script: [
        {
          innerHTML: `window.xla = window.xla || []
            window.xla.push({
              type: 'config',
              appid: 20143,
              secret: 'f47f3dd4372353f4b611d28bb694fff8'
            })`,
          vmid: 'xla'
        }
      ],
      __dangerouslyDisableSanitizers: ['script']
    }
  }
}
</script>
