import { requestShoulei } from '@/api/fetch-native'

/**
 *
 * 预览压缩文件列表 http://wiki.xunlei.cn/pages/viewpage.action?pageId=55675309#id-%E8%BF%85%E9%9B%B7%E4%BA%91%E7%9B%98%E5%9C%A8%E7%BA%BF%E8%A7%A3%E5%8E%8B%E7%BC%A9-%E9%A2%84%E8%A7%88%E5%8E%8B%E7%BC%A9%E6%96%87%E4%BB%B6%E5%88%97%E8%A1%A8
 * 接口管理后台：https://xapi.office.k8s.xunlei.cn/project/2138/interface/api/28834
 *
 * @param {Object}
 */
export function decompressV1List ({ path, file_id, gcid, password, file_space }) {
  return requestShoulei('/decompress/v1/list', {
    path,
    file_id,
    gcid,
    password,
    file_space
  }, {
    method: 'POST'
  })
}

export function decompressV1Decompress ({ gcid, file_id, password, default_parent = false, parent_id, files, parent_full_path, file_space, parent_space }) {
  return requestShoulei('/decompress/v1/decompress', {
    gcid,
    file_id,
    password,
    default_parent,
    parent_id,
    files,
    parent_full_path,
    file_space,
    parent_space
  }, {
    method: 'POST',
    // 不将data数据添加到params中, 兼容之前封装的方法
    noAssignParams: true
  })
}

export function decompressV1Cancel ({ file_id }) {
  return requestShoulei('/decompress/v1/cancel', {
    file_id,
  }, {
    method: 'POST',
    // 不将data数据添加到params中, 兼容之前封装的方法
    noAssignParams: true
  })
}

export function decompressV1Download ({ gcid, file_id, password, default_parent = false, parent_id, files, parent_full_path, file_space, parent_space }) {
  return requestShoulei('/decompress/v1/download', {
    gcid,
    file_id,
    password,
    default_parent,
    parent_id,
    files,
    parent_full_path,
    file_space,
    parent_space,
  }, {
    method: 'POST',
    noAssignParams: true
  })
}

export function decompressV1Progress ({ taskId }) {
  return requestShoulei('/decompress/v1/progress', {
    task_id: taskId
  }, {
    method: 'GET'
  })
}
