export default ({ app: { router }, store }) => {
  /* 每次路由变更时进行pv统计 */
  if (process.browser) {
    const hm = document.createElement('script')
    hm.src = 'https://hm.baidu.com/hm.js?83f6eede71468132bb37c064fd6a7ec3'
    document.body.appendChild(hm)
  }
  router.afterEach((to, from) => {
    /* 告诉增加一个PV */
    try {
      window._hmt = window._hmt || []
      window._hmt.push(['_trackPageview', to.fullPath])
    } catch (e) {

    }
  })
}
