import sget from '@xunlei/sget'
import { getLotteryInfo, lottery, getUserId } from '../api/gold-lottery'

export const state = () => ({
  info: {}
})

export const mutations = {
  setInfo (state, info) {
    state.info = info
  },
  setCoin (state, coin) {
    state.info.coin = coin
  }
}

export const actions = {
  getLotteryInfo ({ commit }, { uid }) {
    return getLotteryInfo(uid).then(res => {
      if (res.result === 'ok') {
        commit('setInfo', res.data || {})
      }
    })
  },
  lottery ({ commit }, { uid, sessionId, ...rest }) {
    return lottery({ uid, sessionId, ...rest }).then(res => {
      if (res.result === 'ok') {
        const data = res.data || {}
        return data
      } else {
        return {
          ...res.data,
          code: res.code,
          prize_type: 0
        }
      }
    })
  },
  getUserId () {
    return getUserId()
  }
}
