import { fetchDataWithShoulei } from '@/api/fetch-res'
// 获取storid
export async function getStorid ({ url, gcid }) {
  return fetchDataWithShoulei(
    '/remotestorage/wechart_set',
    {
      url,
      gcid
    },
    { method: 'post' }
  )
}

// 获取红包口令
export async function getPsw ({ url, uid }) {
  return fetchDataWithShoulei('/dlj_create', {
    url,
    uid
  }, { method: 'post' })
}

// 获取资源
export async function getResourceConfig ({ platform = 1 } = {}) {
  return fetchDataWithShoulei('/xlppc.red.packet.fission/api/get_url_whitelist', { platform })
}

// 获取分享模版
// export async function getShareTemplateJson () {
//   return axios.get(
//     'https://static-xl9-ssl.xunlei.com/json/password_red_envelope.json'
//   )
// }
export async function getShareTemplateJson () {
  return fetchDataWithShoulei('/xlppc.red.packet.fission/api/get_share_template_content')
}

export async function getShareTemplateConfig () {
  return fetchDataWithShoulei('/xlppc.red.packet.fission/api/get_share_template')
}

// 获取历史收益
export async function getWithdrawTotalNum () {
  return fetchDataWithShoulei('/xlppc.red.packet.fission/api/withdraw_sum')
}

// 获取提现梯度
export async function getCashGrades () {
  return fetchDataWithShoulei('/xlppc.red.packet.fission/api/withdraw_grades')
}

// 红包提现
export async function envelopCash ({ cash_num, openid }) {
  return fetchDataWithShoulei(
    '/xlppc.red.packet.fission/api/withdraw',
    {
      cash_num,
      openid
    },
    { method: 'post' }
  )
}

// 会员卡提现
export async function envelopVipCard ({ vipcard_id }) {
  return fetchDataWithShoulei('/xlppc.red.packet.fission/api/redeem_vipcard', {
    vipcard_id
  }, { method: 'post' })
}

// 会员代金券提现
export async function envelopVoucher ({ voucher_id }) {
  return fetchDataWithShoulei('/xlppc.red.packet.fission/api/redeem_voucher', {
    voucher_id
  }, { method: 'post' })
}

// 获取提现历史
export async function getCashHistory ({ cursor, size }) {
  return fetchDataWithShoulei('/xlppc.red.packet.fission/api/cash_history', {
    cursor,
    size
  })
}

// 获取全局用户提现晒单列表。
export async function getGlobalWithdrawList () {
  return fetchDataWithShoulei(
    '/xlppc.red.packet.fission/api/get_global_withdraw_list'
  )
}
// 获取个人收益列表
export async function getRewardRecordList ({ offset, limit = 15 }) {
  return fetchDataWithShoulei(
    `/xlppc.red.packet.fission/api/get_reward_record?offset=${offset}&limit=${limit}`
  )
}
// 获取全站已开红包列表
export async function getGlobalOpendEnvelope () {
  return fetchDataWithShoulei(
    '/xlppc.red.packet.fission/api/get_global_opened_red_packet'
  )
}
// 获取活动主页用户信息
export async function getUserInfo () {
  return fetchDataWithShoulei(
    '/xlppc.red.packet.fission/api/get_user_page_info'
  )
}
// 获取用户待开红包数量
export async function getUnopenedEnvelopeCount () {
  return fetchDataWithShoulei(
    '/xlppc.red.packet.fission/api/get_unopened_red_packet_num'
  )
}
// 开红包
export function openRedEnvelope ({ num = 1, type }) {
  return fetchDataWithShoulei(
    '/xlppc.red.packet.fission/api/open_red_packet',
    {
      type,
      num
    },
    { method: 'post' }
  )
}
// 获取二重红包列表
export function getSecondEnvelopeList () {
  return fetchDataWithShoulei('/xlppc.red.packet.fission/api/get_second_red_packet_info')
}
