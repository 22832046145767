/**
 * 碎片
 */

import {
  getTotalFragment,
  reportTask,
  getTaskList,
  querySpaceProgress,
  receiveFragments,
  exchangeSpace,
  getFirstParticipate,
  checkActivity
} from '@/api/activity/collect-fragment/fragment.js'

export const state = () => ({
  // 任务列表
  tasks: [],
  totalFragment: 0,
  //   空间进度里程碑
  markers: [],
  next_space_fragments: 0,
  // 进度比例
  space_progress_rate: 0,
  // 是否是新用户
  isFirstParticipate: false,
  // 是否展示扩容成功弹窗
  isShowExpansionSucc: false,
  maxSpace: '1T',
  // todo: 空间超过用户的比例
  percentage: 0,
  showActivity: false,
  shouldUpdateSpaceProgress: false,
  // 是否展示早鸟券 早鸟券下线后delete
  isShowTicketEntrance: false
})

export const mutations = {
  setTasksList(state, task_list) {
    state.tasks = task_list
  },
  setTotalFragment(state, total) {
    state.totalFragment = total
  },
  // 早鸟券下线后delete
  setShowTicketEntrance(state, is_show) {
    state.isShowTicketEntrance = is_show
  },
  setFirstParticipate(state, is_first) {
    state.isFirstParticipate = is_first
  },
  setShowExpansionSucc(state, is_show) {
    state.isShowExpansionSucc = is_show
  },
  setMaxSpace(state, max_space) {
    state.maxSpace = max_space
  },
  setUpdateSpaceProgress(state, shouldUpdateSpaceProgress) {
    state.shouldUpdateSpaceProgress = shouldUpdateSpaceProgress
  },
  setSpaceProgressInfo(
    state,
    { space_status, next_space_fragments, space_progress_rate }
  ) {
    state.markers = space_status
    state.next_space_fragments = next_space_fragments
    state.space_progress_rate = space_progress_rate
  },
  setActivityShow(state, bol) {
    state.showActivity = bol
  }
}

export const actions = {
  // 获取任务列表
  getTaskList({ commit }, { os }) {
    return getTaskList(os).then(resp => {
      if (resp.code === 0) {
        commit('setTasksList', resp.data.task_list)
        return resp
      } else {
        return Promise.reject({
          code: resp.code,
          error_description: resp.result
        })
      }
    })
  },
  // 碎片总数
  getTotalFragment({ commit }, { os }) {
    return getTotalFragment(os).then(resp => {
      if (resp.code === 0) {
        commit('setTotalFragment', resp.data.balance)
        commit(
          'setShowTicketEntrance',
          !!(resp && resp && resp.data && resp.data.show_ticket_entry)
        )
      } else {
        return Promise.reject({
          code: resp.code,
          error_description: resp.result
        })
      }
    })
  },
  // 领取碎片
  receiveFragments({ commit }, { task_id, os }) {
    return receiveFragments(os, {
      task_id
    }).then(resp => {
      if (resp.code === 0) {
        // 更新碎片总数和任务列表状态
        // 领取碎片成功后，需更新空间进度条
        commit('setUpdateSpaceProgress', true)
        return Promise.resolve(resp)
      } else {
        return Promise.reject({
          code: resp.code,
          error_description: resp.result
        })
      }
    })
  },
  // 上报任务
  reportTask({ commit }, { task_id, os }) {
    return reportTask(os, {
      task_id
    }).then(resp => {
      // console.log('store resp :>> ', resp);
      // resp.code = 10
      if (resp.code === 0) {
        return Promise.resolve(resp)
      } else {
        return Promise.reject({
          code: resp.code,
          error_description: resp.result
        })
      }
    })
  },
  // 空间进度条
  getSpaceProgress({ commit }, { task_id, os }) {
    commit('setUpdateSpaceProgress', false)
    return querySpaceProgress(os).then(resp => {
      if (resp.code === 0) {
        const {
          space_status,
          next_space_fragments,
          space_progress_rate
        } = resp.data
        commit('setSpaceProgressInfo', {
          space_status,
          next_space_fragments,
          space_progress_rate
        })
      } else {
        return Promise.reject({
          code: resp.code,
          error_description: resp.result
        })
      }
    })
  },
  // 领取空间
  exchangeSpace({ commit }, { os }) {
    return exchangeSpace(os).then(resp => {
      console.log('resp1 :>> ', resp)
      if (resp.code === 0) {
        return Promise.resolve(resp)
      } else {
        return Promise.reject({
          code: resp.code,
          error_description: resp.result
        })
      }
    })
  },
  // 领取空间
  getFirstParticipate({ commit }) {
    return getFirstParticipate().then(resp => {
      if (resp.code === 0) {
        commit('setFirstParticipate', resp.data.is_first)
        return resp
      } else {
        return Promise.reject({
          code: resp.code,
          error_description: resp.result
        })
      }
    })
  },
  checkActivity({ commit }, { os }) {
    return checkActivity(os).then(resp => {
      if (resp.code === 0) {
        commit('setActivityShow', resp.data.show_activity_entrance)
        return resp
      }
    })
  }
}
