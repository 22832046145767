/**
 * 做任务-集碎片相关
 */
import { fetchDataForDrive } from '@/api/fetch-fragment'

// 查询碎片余额
export function getTotalFragment(os = 'android') {
  return fetchDataForDrive(
    `xlppc.pan.fragments/api/fragmentsBalance/${os}`,
    {},
    {
      method: 'GET'
    }
  )
}
// 上报任务，获得碎片
export function reportTask(os = 'android', params = {}) {
  return fetchDataForDrive(`xlppc.pan.fragments/api/reportTask/${os}`, params, {
    method: 'POST'
  })
}
// 任务列表
export function getTaskList(os = 'android') {
  console.log('os :>> ', os)
  return fetchDataForDrive(
    `xlppc.pan.fragments/api/getTaskStatus/${os}`,
    {},
    {
      method: 'GET'
    }
  )
}

// 空间兑换进度
export function querySpaceProgress(os = 'android') {
  return fetchDataForDrive(
    `xlppc.pan.fragments/api/spaceProgress/${os}`,
    {},
    {
      method: 'GET'
    }
  )
}

// 领取碎片
export function receiveFragments(os = 'android', params = {}) {
  return fetchDataForDrive(
    `xlppc.pan.fragments/api/getTaskFragments/${os}`,
    params,
    {
      method: 'POST'
    }
  )
}

// 用碎片兑换云盘空间
export function exchangeSpace(os = 'android', params = {}) {
  return fetchDataForDrive(
    `xlppc.pan.fragments/api/exchangeSpace/${os}`,
    params,
    {
      method: 'POST'
    }
  )
}

// 是否首次参与活动
export function getFirstParticipate() {
  return fetchDataForDrive(
    `xlppc.pan.fragments/api/isFirstParticipate`,
    {},
    {
      method: 'GET'
    }
  )
}

// 是否展示活动入口
export function checkActivity(os = 'android') {
  return fetchDataForDrive(`xlppc.pan.fragments/api/showActivityEntrance/${os}`)
}
