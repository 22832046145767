import Vue from 'vue'

const install = function (Vue) {
  let touchHandle
  let timer = null
  let touchClick
  let cancel
  Vue.directive('touch', {
    bind: function (el, { value, arg = 500 }) {
      touchHandle = function (e) {
        // 按下去的样式
        el.classList.add('touch')

        timer = setTimeout(() => {
          value.long && value.long(e)
        }, arg)
        return true
      }

      cancel = function (e) {
        el.classList.remove('touch')

        // 检查计时器是否有值
        if (timer !== null) {
          clearTimeout(timer)
          timer = null
        }
      }

      touchClick = function (e) {
        cancel(e)
        value.touch && value.touch(e)
      }
      // 长按的时候 touchend 经常不触发，而是触发了 touchcancel
      el.addEventListener('touchstart', touchHandle)
      el.addEventListener('touchend', cancel)
      el.addEventListener('touchcancel', cancel)
      el.addEventListener('touchmove', cancel)
      el.addEventListener('click', touchClick)
    },
    unbind: function (el) {
      touchHandle && el.removeEventListener('touchstart', touchHandle)
      cancel && el.removeEventListener('touchend', cancel)
      cancel && el.removeEventListener('touchcancel', cancel)
      cancel && el.removeEventListener('touchmove', cancel)
      touchClick && el.removeEventListener('click', touchClick)
    },
  })
}

install(Vue)
