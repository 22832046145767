import Vue from 'vue'
import scrollLoad from '@xunlei/scroll-load'

const plugin = {
  install (Vue) {
    Vue.directive('load', { // v-load

      // 当被绑定的元素插入到 DOM 中时……
      inserted: function (el, { value: { func = () => {}, root = null, distance = '0px' } }) {
        scrollLoad(
          func, // 目标元素每次出现时执行的回调函数（隐藏再出现则再次执行）
          {
            target: el,
            root, // root为null时表示root是视口区域
            distance  // 当root存在时,用于提前或延迟加载
          }
        )
      }
    })
  }
}

Vue.use(plugin)
