import {
  decompressV1List,
  decompressV1Decompress,
  decompressV1Download,
  decompressV1Progress,
  decompressV1Cancel
} from '@/api/decompress'
// import Vue from 'vue'
// import sget from '@xunlei/sget'
export const state = () => ({
  password: '',
  limitData: null,
  vipBtnDesc: '',
  vipBtnTip: '',
  vipPayconfid: '',
  vipAidfrom: '',
  vipPayLink: ''
})

export const mutations = {
  setPassword (state, pwd) {
    state.password = pwd
  },
  update (state, params) {
    for (const key in params) {
      state[key] = params[key]
    }
  },
}

export const actions = {
  getDecompressList ({}, params) {
    return decompressV1List(params)
      .then((res) => {
        res.files.length && res.files.map((elm) => {
          elm.selected = false
          return elm
        })
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  decompress ({}, params) {
    return decompressV1Decompress(params)
      .then((res) => {
        return res
      })
  },
  download ({}, params) {
    return decompressV1Download(params)
      .then((res) => {
        return res
      })
  },
  getTaskInfo ({}, params) {
    return decompressV1Progress(params)
      .then((res) => {
        return res
      })
  },
  /** 取消解压 */
  cancelDecompress({}, params) {
    return decompressV1Cancel(params).then(res => {
      return res
    })
  }
}
