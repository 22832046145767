interface IAndroidWebviewBridge {
  sendMessage (method: string, params?: string, callbackFunctionName?: string): void
}
function getAndroidClient () {
  // @ts-ignore
  const AndroidWebviewBridge: IAndroidWebviewBridge | undefined = window.XLJSWebViewBridge
  return AndroidWebviewBridge
}

export const isAndroidNative = () => /Android/i.test(navigator.userAgent) && !!getAndroidClient()

class PageBackEvent {
  // 是否已设置监听客户端返回事件（接管客户端返回处理）
  registered = false
  uid = ''
  // 客户端仅允许设置一次返回监听，页面扩展至允许多次监听
  listeners: Function[] = []
  constructor (autoInit = false) {
    const uid = Date.now().toString(36)
    this.uid = uid
    if (autoInit) {
      this.init()
    }
  }

  callSafely (fun) {
    if (isAndroidNative()) {
      fun()
    } else {
      console.warn('请在安卓客户端下使用')
    }
  }

  // nuxt项目需要手动调用
  init () {
    this.callSafely(this.register)
  }

  // 注册监听客户端返回事件
  private register () {
    const func = 'onBack_' + this.uid
    if (this.registered) {
      return func
    }
    window[func] = () => {
      this.listeners.forEach(cb => cb())
    }
    const AndroidWebviewBridge = getAndroidClient()
    AndroidWebviewBridge?.sendMessage('xlRegisterEvent', JSON.stringify({
      event: 'onBackPressed',
      callback: func,
    }))
    this.registered = true
    return func
  }

  // 取消监听客户端返回事件
  private unregister (clear = true) {
    const func = 'onBack_' + this.uid
    delete window[func]
    if (clear) {
      this.listeners.length = 0
    }
    const AndroidWebviewBridge = getAndroidClient()
    AndroidWebviewBridge!.sendMessage('xlUnRegisterEvent', JSON.stringify({
      event: 'onBackPressed',
    }))
    this.registered = false
  }

  // 暂停监听，即取消监听客户端返回事件，但保留现有回调函数，以便后续恢复
  stop () {
    if (!this.registered) return
    this.callSafely(() => {
      this.unregister(false)
    })
    this.unregister(false)
  }

  // 恢复监听
  start () {
    if (this.registered) return
    this.callSafely(() => {
      this.register()
    })
  }

  // 添加监听回调
  addListener (callback: () => void) {
    if (this.listeners.every(cb => cb !== callback)) {
      this.listeners.push(callback)

      return () => {
        this.removeListener(callback)
      }
    }
    return () => undefined
  }

  // 移除监听回调
  removeListener (callback: () => void) {
    const targetIndex = this.listeners.findIndex(cb => cb === callback)
    if (targetIndex > -1) {
      this.listeners.splice(targetIndex, 1)
      return true
    }
    return false
  }

  // 当前没有监听时，销毁监听器，用于组件unmount阶段
  destroyIfNoListener () {
    if (!this.listeners.length) {
      this.destroy()
    }
  }

  destroy () {
    this.unregister(true)
  }
}

const $pageBack = new PageBackEvent(false)

export default $pageBack
