import axios from 'axios'
import config from '@/config'
import { checkIsJSON } from '@/utils/util'
import { parseData } from "./fetch-res"
import { getStore } from '@/plugins/vuex-store-plugin'

const client = process.client
  ? (() => {
      const client = require('@/utils/clientApi').default
      return client
    })()
  : null

const DRIVE_API = {
  test: 'https://api-beta-drive.office.k8s.xunlei.cn/',
  prod: 'https://api-pan.xunlei.com/'
}

/**
 * 获取云盘相关的接口：http://drive.office.k8s.xunlei.cn/?urls.primaryName=file.swagger.json#/
 * @param {string} url - 接口地址
 * @param {object} data - 数据
 * @param {object} option - 选项（method：请求方法'get'或'post'；headers：放入请求头的内容）
 */
export async function fetchDataForDrive(url, data = {}, option = {}) {
  url = option.env
    ? `${DRIVE_API[option.env]}${url}`
    : `${DRIVE_API[config.driveAPI]}${url}`

  const parsedData = await parseData(data, option)
  const { method, params } = parsedData

  // 每次请求前从客户端获取accessToken
  let accessToken
  let versionCode
  try {
    if (client.isIOS()) {
      const [err, user] = await client.callNativeHandler('userInfo')
      accessToken = user.authorize
    } else {
      accessToken = await client.ssoCallFunction('getAccessToken')
    }
    versionCode = await getStore().state.native.version
    if (checkIsJSON(accessToken) && accessToken.includes('error'))
      throw new Error()
  } catch (error) {
    return Promise.reject('请重新登录')
  }
  const Authorization = 'Bearer ' + accessToken

  // 一键导入的接口只能配authorization，加上其他都会出现跨域
  const headers = Object.assign(
    {},
    {
      Authorization: Authorization,
      'X-Client-Version-Code': versionCode
    },
    option.headers
  )

  return axios({
    method,
    url,
    data,
    params,
    headers
  })
    .then(res => {
      return res.data
    })
    .catch(res => {
      return Promise.reject(res.response.data)
    })
}
